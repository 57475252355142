'use client';

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC, useCallback, useState } from 'react';

import { Image } from 'src/general/components/Image/Image';

import { getYoutubeVideoEmbedId } from './YoutubeVideo.helper';
import styles from './YoutubeVideo.module.scss';

type YoutubeVideoProps = {
  width?: number;
  height?: number;
  videoUrl: string;
  thumbnailImageUrl: string;
  setButtonCentered?: boolean;
};

export const YoutubeVideo: FC<YoutubeVideoProps> = ({
  height,
  thumbnailImageUrl,
  videoUrl,
  width,
  setButtonCentered,
}) => {
  const [onClickPlay, setOnClickPlay] = useState<boolean>(false);

  const onImageClick = useCallback(() => {
    setOnClickPlay(true);
  }, []);

  return (
    <div className={styles.root}>
      {onClickPlay || isEmpty(thumbnailImageUrl) ? (
        <iframe
          width={width ?? 1280}
          height={height ?? 720}
          //Auto play to trigger the video to play without having to press the red play button again
          src={`https://www.youtube.com/embed/${getYoutubeVideoEmbedId(videoUrl)}?autoplay=${onClickPlay ? 1 : 0}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          className={classNames(styles.video, 'video')}
        />
      ) : (
        <button className={styles.customImageAndPlayButton} onClick={onImageClick} data-testid="VIDEO_TRIGGER">
          <Image
            src={thumbnailImageUrl}
            width={width ?? 1280}
            height={height ?? 720}
            alt="Play video"
            className={styles.image}
          />
          <div className={classNames(styles.buttonContainer, { [styles.setButtonCentered]: setButtonCentered })}>
            <Image
              src="/images/components/YoutubeVideo/youtube-play-button.svg"
              alt="Video play button"
              width={120}
              height={120}
              className={styles.playButton}
            />
          </div>
        </button>
      )}
    </div>
  );
};
