import classNames from 'classnames';
import { FC } from 'react';

import { ArticleTile } from 'src/content-hub/components/ArticleTile/ArticleTile';
import { PageArticleDataNoContent } from 'src/data/Contentful/ContentfulGetArticle';

import styles from './ArticlesGrid.module.scss';

interface Props {
  className?: string;
  items: PageArticleDataNoContent[];
}

/**
 * Base Article grid component used in various content hub locations.
 */
export const ArticlesGrid: FC<Props> = ({ className, items }) => (
  // Attribute role="list" is required for Safari to recognise as a list in accessibility tree if list-style is 'none'.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style#accessibility_concerns.
  <ul role="list" className={classNames(styles.root, className)}>
    {items.map((item) => (
      <li key={item.sys.id}>
        <ArticleTile article={item} />
      </li>
    ))}
  </ul>
);
