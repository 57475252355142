import { ComponentProps, FC } from 'react';

import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { getMicrocopy } from 'src/stores/microcopyStore';

import { NewsletterSignupWrapper } from './NewsletterSignupWrapper';

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const NewsletterSignupLegacy: FC<
  Omit<ComponentProps<typeof NewsletterSignupWrapper>, 'microcopy' | 'recaptchaNotice'>
> = (props) => {
  const privacy = getMicrocopy(MICROCOPY.AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS, true);
  const success = getMicrocopy(MICROCOPY.CONTENT_HUB_NEWSLETTER_SUCCESS);
  const submit = getMicrocopy(MICROCOPY.CONTENT_HUB_NEWSLETTER_SUBMIT);

  return (
    <NewsletterSignupWrapper
      {...props}
      microcopy={{
        privacy,
        success,
        submit,
      }}
    />
  );
};
