import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePathname } from 'next/navigation';
import { ComponentProps, FC, ReactNode, useEffect } from 'react';

import { Button } from 'src/general/components/Button/Button';
import { CTAButton } from 'src/general/components/Button/CTAButton';
import { FormikInput } from 'src/general/components/forms/FormikInput/FormikInput';
import { formatNumber } from 'src/general/helpers/formatNumber';
import { FinanceCalculatorSlider } from 'src/hygiene/components/FinanceCalculatorSlider/FinanceCalculatorSlider';
import { DataLayerFinanceCalculatorEvent, pushToDataLayerFinanceCalculator } from 'src/utils/pushToDataLayer';

import {
  CALCULATOR_MAX_DEPOSIT,
  CALCULATOR_MAX_PURCHASE,
  CALCULATOR_MIN_DEPOSIT,
  CALCULATOR_MIN_PURCHASE,
  calculateWeeklyPayment,
} from '../../FinanceCalculator.helper';
import { FinanceEnquiryFormValues } from '../../FinanceCalculator.type';
import styles from './MyRepayment.module.scss';

type MyRepaymentProps = {
  loanTermsArray: number[];
  interestRateRange: Omit<ComponentProps<typeof FinanceCalculatorSlider>, 'formikFieldName'>;
  values: FinanceEnquiryFormValues;
  setFieldValue: FormikProps<FinanceEnquiryFormValues>['setFieldValue'];
  microcopy: {
    hygieneFinanceCalculatorTitle: ReactNode;
    hygieneFinanceCalculatorInputLabel2: ReactNode;
    hygieneFinanceCalculatorInputLabel3: ReactNode;
    hygieneFinanceCalculatorLoanTitle: ReactNode;
    hygieneFinanceCalculatorLoanTitleEstimatedRepayment: ReactNode;
    hygieneFinanceCalculatorWeeklyFee: ReactNode;
    personalizedQuoteFinanceCalculatorCta: ReactNode;
  };
};

export const MyRepayment: FC<MyRepaymentProps> = ({
  loanTermsArray,
  interestRateRange,
  values,
  setFieldValue,
  microcopy,
}) => {
  const flags = useFlags();
  const pathName = usePathname();

  useEffect(() => {
    const { purchasePrice, depositAmount, interestRate, termOfLoan } = values;
    if (
      `${purchasePrice}`.trim() !== '' &&
      `${depositAmount}`.trim() !== '' &&
      `${interestRate}`.trim() !== '' &&
      `${termOfLoan}`.trim() !== ''
    ) {
      setFieldValue('weeklyPayment', calculateWeeklyPayment(purchasePrice!!, interestRate, termOfLoan, depositAmount));
    }
  }, [values]);

  return (
    <div className={styles.layout}>
      <div>
        <div className={styles.title}>{microcopy.hygieneFinanceCalculatorTitle}</div>
        <div className={styles.inputWrapper}>
          <FormikInput
            name="purchasePrice"
            iconLeft="$"
            label={<>{microcopy.hygieneFinanceCalculatorInputLabel3}</>}
            type="number"
            inputProps={{ min: CALCULATOR_MIN_PURCHASE, max: CALCULATOR_MAX_PURCHASE }}
            onTrackingEvent={(value: string) => {
              pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_purchase_price, {
                tab: 'MY_REPAYMENTS',
                value,
              });
            }}
          />
          <FormikInput
            name="depositAmount"
            iconLeft="$"
            label={<>{microcopy.hygieneFinanceCalculatorInputLabel2}</>}
            type="number"
            inputProps={{ min: CALCULATOR_MIN_DEPOSIT, max: CALCULATOR_MAX_DEPOSIT }}
            onTrackingEvent={(value: string) => {
              pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_deposit_amount, {
                tab: 'MY_REPAYMENTS',
                value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.loanBlock}>
        <div className={styles.title}>{microcopy.hygieneFinanceCalculatorLoanTitle}</div>
        <div className={styles.buttonsContainer}>
          {loanTermsArray.map((item) => (
            <Button
              key={item.toString()}
              variant="secondary"
              size="small"
              type="button"
              className={classNames(styles.termButton, {
                [styles.active]: item === values.termOfLoan,
              })}
              onClick={() => {
                setFieldValue('termOfLoan', item);
                pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_term_of_loan, {
                  tab: 'MY_REPAYMENTS',
                  value: item,
                });
              }}
              fullWidth
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      <FinanceCalculatorSlider
        minRate={interestRateRange.minRate}
        maxRate={interestRateRange.maxRate}
        formikFieldName="interestRate"
        onTrackingEvent={(value) => {
          pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_interest_rate, {
            tab: 'MY_REPAYMENTS',
            value,
          });
        }}
      />
      <div className={styles.paymentBlock}>
        <div>
          <div className={classNames(styles.normalText, 'c-fw-normal', 'c-fs-body1')}>
            {microcopy.hygieneFinanceCalculatorLoanTitleEstimatedRepayment}
          </div>
          <span className={classNames(styles.fee, 'c-fw-bold', 'c-fs-h2')}>
            {formatNumber(values.weeklyPayment ?? 0, { prepend: '$' })}
          </span>
          <span className={classNames(styles.subFee, 'c-fw-bold', 'c-fs-h4')}>
            {microcopy.hygieneFinanceCalculatorWeeklyFee}
          </span>
        </div>
        {flags?.personalisedQuoteForm && (
          <CTAButton
            size="medium"
            variant="accent"
            {...{
              label: microcopy.personalizedQuoteFinanceCalculatorCta,
              buttonType: 'link',
              href: `/personalised-quote?purchasePrice=${values.purchasePrice}&depositAmount=${values.depositAmount}&origin=${pathName}`,
            }}
          />
        )}
      </div>
    </div>
  );
};
