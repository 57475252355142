'use client';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { enqueueSnackbar } from 'notistack';
import { FC, ReactNode, useCallback, useState } from 'react';

import { IconCircleCheck } from 'src/general/Icons/IconCircleCheck';
import { Button } from 'src/general/components/Button/Button';
import { InputFormik } from 'src/general/components/forms/Input/InputFormik';
import { pushCarmaEvent } from 'src/general/helpers/carmaCritical';
import { getRecaptchaToken } from 'src/general/helpers/getRecaptchaToken';
import { FlexibleSection } from 'src/hygiene/sections/flexible-content/flexibleContent.type';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import { RecaptchaNotice } from 'src/general/components/RecaptchaNotice/RecaptchaNotice';
import styles from './NewsletterSignup.module.scss';
import { SignupData, SignupValidation } from './NewsletterSignup.type';

interface Props extends FlexibleSection {
  sectionData: {
    title: ReactNode;
  };
  microcopy: {
    privacy: ReactNode;
    success: ReactNode;
    submit: ReactNode;
  };
  className?: string;
  onSubmit: (email: string, recaptchaToken?: string) => Promise<true | string>;
}

export const NewsletterSignup: FC<Props> = ({ sectionData, microcopy, onSubmit, className }) => {
  const flags = useFlags();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (formData: SignupData) => {
      let recaptchaToken = undefined;
      if (flags?.recaptcha) {
        try {
          recaptchaToken = await getRecaptchaToken('lead_form_submit');
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Error with reCaptcha', { variant: 'error' });
          return;
        }
      }

      const result = await onSubmit(formData.email, recaptchaToken);

      if (result === true) {
        // Success
        pushCarmaEvent({
          event: 'form_submission',
          metadata: {
            email: formData.email,
            component_name: 'NewsletterSignup',
          },
        });
        pushToDataLayer({
          event: 'newsletter_signup',
          email: formData.email,
        });
        setIsSuccess(true);
      } else {
        enqueueSnackbar(result, { variant: 'error' });
      }
    },
    [onSubmit, flags?.recaptcha],
  );

  return (
    <section className={classNames(styles.root, className)}>
      <h3 className={styles.title}>{sectionData.title}</h3>
      {isSuccess ? (
        <div className={styles.messageContainer}>
          <IconCircleCheck className={styles.iconTick} />
          <p className={styles.message}>{microcopy.success}</p>
        </div>
      ) : (
        <>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={SignupValidation}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={styles.content}>
                <InputFormik
                  name="email"
                  id="newsletter-email"
                  label="Email address"
                  type="email"
                  disabled={isSubmitting}
                  data-testid="newsletter-email"
                />
                <Button type="submit" className={styles.button} isLoading={isSubmitting}>
                  {microcopy.submit}
                </Button>
              </Form>
            )}
          </Formik>
          <footer className={styles.footer}>
            <div className={styles.legal}>{microcopy.privacy}</div>
            <RecaptchaNotice />
          </footer>
        </>
      )}
    </section>
  );
};
