import Link from 'next/link';
import { FC } from 'react';

import classNames from 'classnames';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './LinkBlock.module.scss';

interface LinkBlockProps extends FlexibleSection {
  sectionData: {
    title?: string;
    linkNames: Array<{
      name: string;
      href: string;
    }>;
  };
}

export const LinkBlock: FC<LinkBlockProps> = ({ sectionData: { title, linkNames }, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    <header className={styles.header}>
      <h2 className="c-fs-h4 c-fw-bold">{title}</h2>
    </header>
    <div className={styles.container}>
      {linkNames.map((item) => (
        <Link key={item.name} href={item.href} className={classNames(styles.link, 'c-fs-h6')}>
          {item.name}
        </Link>
      ))}
    </div>
  </section>
);
