import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';

import classNames from 'classnames';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './CTABlock.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    cta: ComponentProps<typeof CTAButton>[];
  };
}

export const CTABlock: FC<Props> = ({ sectionData: { cta }, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    {cta.map((ctaItem) => (
      <CTAButton key={ctaItem.href} {...ctaItem} />
    ))}
  </section>
);
