import { object, string } from 'yup';

const errorEmail = 'Please enter your email address';
const errorEmailValid = 'Please enter a valid email address';

export const SignupValidation = object({
  email: string().email(errorEmailValid).required(errorEmail),
});

export type SignupData = ReturnType<typeof SignupValidation.validateSync>;
