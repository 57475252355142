import { AxiosResponse } from 'axios';

import { carmaClientLegacy } from 'src/services/instance/carmaClientLegacy';

type NewsletterLeadApiPayload = {
  email: string;
  recaptcha_token?: string;
};

/**
 * Create a newsletter lead
 */
export const createNewsletterLead = (email: string, recaptchaToken?: string) =>
  carmaClientLegacy
    .post<any, AxiosResponse<{}>, NewsletterLeadApiPayload>(`/enquiry/newsletter`, {
      email,
      recaptcha_token: recaptchaToken,
    })
    .then((body) => body.data);
