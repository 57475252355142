import { z } from 'zod';

import { tradeInSearchCarRegoSchema, tradeInSearchCarVINSchema } from './TradeInSearchCar.schema';

const createAction =
  <Schema>(schema: z.ZodSchema<Schema>) =>
  (formData: FormData) => {
    const data = Object.fromEntries(formData) as Record<string, string>;
    const validatedFields = schema.safeParse(data);

    return validatedFields.success
      ? { status: 'success', data: validatedFields.data }
      : { status: 'errorValidation', errors: validatedFields.error.flatten().fieldErrors };
  };

export const tradeInSearchCarRegoAction = createAction(tradeInSearchCarRegoSchema);
export const tradeInSearchCarVINAction = createAction(tradeInSearchCarVINSchema);
