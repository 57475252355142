'use client';

import { isEmpty } from 'lodash';
import { ComponentProps, FC } from 'react';
import { Fetcher } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { fetchRecommendedVehicles } from 'src/data/ProductApi/ProductApiListVehicles';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { PlpMarketingTile } from 'src/plp/components/PlpMarketingTile/PlpMarketingTile';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './RecommendedCarsCarousel.module.scss';
import { generateTiles } from './generateTiles';

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    marketingItems?: ComponentProps<typeof PlpMarketingTile>[];
  };
}

const fetcher: Fetcher<ProductListDataPayload, { id: string }> = () => fetchRecommendedVehicles();

export const RecommendedCarsCarousel: FC<Props> = ({ sectionData, sectionBackground }) => {
  const { data, isLoading } = useSWRImmutable({ id: 'fetchRecommendedVehicles' }, fetcher);
  const tiles = generateTiles(isLoading, data?.results, sectionData.marketingItems);

  return (
    <section className={getSectionClasses(sectionBackground)}>
      {!isEmpty(sectionData.title) && (
        <header className={styles.header}>
          <h2 className="c-fs-h4">{sectionData.title}</h2>
        </header>
      )}
      <CarouselSection tiles={tiles} />
    </section>
  );
};
