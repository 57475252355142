'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';

import styles from './ProductImage.module.scss';

interface Props {
  src: string;
  alt: string;
  width?: number;
  imagePriority?: boolean;
  className?: string;
}

const IMAGE_ASPECT_RATIO = 16 / 9;

export const ProductImage: FC<Props> = ({ src, alt, width = 400, imagePriority, className, ...props }) => {
  const height = Math.round(width / IMAGE_ASPECT_RATIO);
  return (
    <Image
      {...props}
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={classNames(styles.root, className)}
      placeholder="empty"
      data-testid="ProductCard-component-image"
      loading={imagePriority ? 'eager' : 'lazy'}
      loader={carmaGenericImageLoader}
      sizes="(max-width: 1136px) 100vw, 30vw"
    />
  );
};
