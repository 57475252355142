import { ReactNode, createElement } from 'react';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingElementName = `h${HeadingLevel}`;
const getHeadingLevelName = (level: HeadingLevel): HeadingElementName => `h${level}`;

/**
 * Given a scaling factor, return an object that maps all heading components to headings scaled by the given factor.
 * A classname with the original heading element is added for reference.
 *
 * @param scalingFactor - The level that a h1 element will be mapped to.
 *
 * @example
 * // (A mapping for h6 isn't given as it can never be mapped lower)
 * scaleDownHeadingElements(4); // returns { h1: 'h4', h2: 'h5', h3: 'h6', h4: 'h6', h5: 'h6' }
 * scaleDownHeadingElements(1); // returns undefined (no mapping needed)
 */
export const scaleDownHeadings = (scalingFactor: HeadingLevel) =>
  // If scaling factor is 1, no mapping is required.
  scalingFactor === 1
    ? undefined
    : ([1, 2, 3, 4, 5] as const).reduce(
        (acc, currentLevel) => ({
          ...acc,
          [getHeadingLevelName(currentLevel)]: ({ children }: { children: ReactNode }) =>
            createElement(
              getHeadingLevelName(Math.min(currentLevel + scalingFactor - 1, 6) as HeadingLevel),
              { className: getHeadingLevelName(currentLevel) },
              children,
            ),
        }),
        {} as any,
      );
