import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { getArticlePublishDate } from 'src/content-hub/helpers/getArticlePublishDate';
import { PageArticleDataNoContent } from 'src/data/Contentful/ContentfulGetArticle';
import { Image } from 'src/general/components/Image/Image';

import styles from './ArticleTile.module.scss';

interface Props {
  article: PageArticleDataNoContent;
  isFeatured?: boolean;
}

export const ArticleTile: FC<Props> = ({ article, isFeatured }) => {
  const size = isFeatured
    ? {
        width: 1064,
        height: 554,
      }
    : {
        width: 438,
        height: 232,
      };

  return (
    <article
      className={classNames(styles.root, {
        [styles.featured]: isFeatured,
      })}
    >
      <Link href={`/street-smarts/content/${article.slug}`} aria-label={article.title} className={styles.link} />
      <div className={styles.imageWrapper}>
        <Image
          src={isFeatured ? article.image.url : article.tileImage.url}
          width={size.width}
          height={size.height}
          alt={article.title}
          className={styles.image}
          priority={isFeatured}
        />
      </div>
      <header className={styles.content}>
        <Link className={styles.articleType} href={`/street-smarts/${article.articleType.slug}`}>
          {article.articleType.title}
        </Link>
        <time className={styles.publication} dateTime={getArticlePublishDate(article, 'YYYY-MM-DD')}>
          {getArticlePublishDate(article)}
        </time>
        <h2 className={styles.title}>{article.title}</h2>
        <p className={styles.description}>{article.articleDescription}</p>
      </header>
    </article>
  );
};
