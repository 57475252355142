import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface IChild {
  text: string;
  link: string;
}

const aboutCarmaHelper = {
  getBelieveBannerData(data: any) {
    const dataBelieveBanner = data?.sectionsCollection.items[0] || {};
    const icon = dataBelieveBanner?.icon?.url || '';
    const title = dataBelieveBanner?.title || '';

    return {
      icon,
      title,
    };
  },

  getQualityCarsData(db: any) {
    const qualityCarsPC = db?.sectionsCollection.items[1] || {};
    const qualityCarsMB = db?.sectionsCollection.items[2] || {};
    const imgPC = qualityCarsPC?.icon?.url || '';
    const imgMB = qualityCarsMB?.icon?.url || '';
    const title = qualityCarsPC?.title || '';
    const content = qualityCarsPC?.body?.json?.content[0]?.content[0].value || '';

    return {
      imgPC,
      imgMB,
      title,
      content,
    };
  },

  getListAboutCarmaData(db: any) {
    const listData = db?.sectionsCollection.items[3] || {};
    const { titlePC, titleMB, question, buttonText } = listData?.content || {};
    const data = listData?.componentsCollection?.items?.map((item: any, idx: number) => ({
      imgUrl: item?.icon?.url || '',
      title: item?.title || '',
      description: item?.body?.json?.content[0]?.content[0].value || '',
      btnText: '',
      isLeft: !(idx % 2),
    }));
    return { titlePC, titleMB, data, question, buttonText };
  },

  getWeBelieve(db: any) {
    const qualityCarsPC = db?.sectionsCollection.items[4] || {};
    const { title, content } = qualityCarsPC?.content || {};
    const data = qualityCarsPC?.componentsCollection?.items?.map((item: any) => ({
      icon: item?.icon?.url || '',
      title: item?.title || '',
      detail: item?.body?.json?.content[0]?.content[0].value || '',
    }));
    return { title, content, data };
  },

  getFooter(data: any) {
    if (data) {
      const popularModels = {
        label: data?.popularModelsLinkBlockHeader || '',
        children: data?.popularModelsLinkBlockCollection?.items?.map((item: IChild) => ({
          label: item?.text || '',
          href: item?.link || '',
        })),
      };
      const popularCarTypes = {
        label: data?.popularCarTypesBlockLinkHeader || '',
        children: data?.popularCarTypesLinkBlockCollection?.items?.map((item: IChild) => ({
          label: item?.text || '',
          href: item?.link || '',
        })),
      };
      const aboutUs = {
        label: data?.aboutUsLinkBlockHeader || '',
        children: data?.aboutUsLinkBlockCollection?.items?.map((item: IChild) => ({
          label: item?.text || '',
          href: item?.link || '',
        })),
      };
      const support = {
        label: data?.supportHeader || '',
        children: data?.supportLinkBlockCollection?.items?.map((item: IChild) => ({
          label: item?.text || '',
          href: item?.link || '',
        })),
      };

      const followUs = {
        label: data?.followUsLinkBlockHeader || '',
        children: data?.followUsBlockLinkCollection?.items?.map((item: IChild) => ({
          label: item?.text || '',
          href: item?.link || '',
        })),
      };

      const licences = documentToReactComponents(data?.description?.json || {});

      const supportContent = {
        title: data?.supportTitle || '',
        description: data?.supportWorkingTime || '',
        button: {
          label: data?.supportTextButton || '',
          icon: data?.supportIconButton?.url ? <img src={data?.supportIconButton?.url} alt="email" /> : null,
        },
        helpModalProps: {
          content: documentToReactComponents(data?.helpModalContent?.json || {}),
        },
      };

      const copyright = data?.copyrightText || '';
      const term = {
        label: data?.termsAndConditions?.text || '',
        href: data?.termsAndConditions?.link || '',
      };
      const policy = {
        label: data?.privaryPolicy?.text || '',
        href: data?.privaryPolicy?.link || '',
      };

      const items = [popularModels, popularCarTypes, aboutUs, support, followUs];
      return { items, licences, supportContent, copyright, term, policy };
    }
    return {};
  },
};

export default aboutCarmaHelper;
