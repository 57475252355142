import { ComponentProps } from 'react';
import { Components } from 'react-markdown';

import { InlineImage } from 'src/hygiene/sections/flexible-content/InlineImage/InlineImage';

export const MarkdownImage: Components['img'] = ({ src, node }) => {
  const data: ComponentProps<typeof InlineImage> = {
    sectionData: {
      title: node.properties?.title?.toString() ?? '',
      subTitle: node.properties?.subtitle?.toString() ?? '',
      images: [
        {
          url: src!,
          description: node.properties?.description?.toString() ?? '',
        },
      ],
    },
  };

  return <InlineImage sectionData={data.sectionData} />;
};
