import { FC } from 'react';
import { Image } from 'src/general/components/Image/Image';

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import styles from './ValueProps.module.scss';

type Props = {
  valueProps?: {
    imageUrl: string;
    title: string;
    description?: string;
  }[];
};

export const ValueProps: FC<Props> = ({ valueProps }) => {
  const hasValueProps = !isEmpty(valueProps);

  if (!hasValueProps) {
    return null;
  }

  return (
    <ul className={classNames(styles.valueProps, 'valueProps')}>
      {valueProps!.map((item) => (
        <li className={classNames(styles.valuePropItem, 'valuePropItem')} key={item.title}>
          <Image
            src={item.imageUrl}
            alt=""
            className={classNames(styles.valuePropImage, 'valuePropImage')}
            width={56}
            height={56}
          />
          <p className={classNames(styles.valuePropTitle, 'valuePropTitle', 'c-fs-body1', 'c-fw-bold')}>{item.title}</p>
          {!isEmpty(item.description) && (
            <p className={classNames(styles.valuePropDescription, 'valuePropDescription', 'c-fs-body2')}>
              {item.description}
            </p>
          )}
        </li>
      ))}
    </ul>
  );
};
