import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { MaskedImage } from 'src/hygiene/components/MaskedImage/MaskedImage';
import { TradeInSearchCar } from 'src/hygiene/components/TradeInEnquiry/TradeInSearchCar/TradeInSearchCar';
import { ValueProps } from 'src/hygiene/components/ValueProps/ValueProps';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './HeroTradeIn.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    subtitle?: string;
    mobileImgUrl: string;
    desktopImgUrl: string;
    valueProps?: {
      imageUrl: string;
      title: string;
      description?: string;
    }[];
  };
}

export const HeroTradeIn: FC<Props> = ({ sectionData, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground), 'header-transparent')}>
    <header className={styles.header}>
      <h1 data-testid="TRADE_IN_BANNER_TITLE" className="c-fs-d1">
        {sectionData.title}
      </h1>
      {!isEmpty(sectionData.subtitle) && (
        <div className={classNames(styles.subtitle, 'c-fs-body1')}>{sectionData.subtitle}</div>
      )}
    </header>
    <div className={styles.form}>
      <TradeInSearchCar />
    </div>
    <div className={styles.images}>
      <MaskedImage
        image={sectionData.mobileImgUrl}
        width={335}
        height={222}
        className={styles.imageMobile}
        maskPosition="top"
      />
      <MaskedImage
        image={sectionData.desktopImgUrl}
        width={690}
        height={564}
        className={styles.imageDesktop}
        maskPosition="left"
      />
    </div>
    <ValueProps valueProps={sectionData.valueProps} />
  </section>
);
