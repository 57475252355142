'use client';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import styles from './RecaptchaNotice.module.scss';

interface Props {
  className?: string;
}

export const RecaptchaNotice: FC<Props> = ({ className }) => {
  const flags = useFlags();

  return flags?.recaptcha ? (
    <div className={classNames(styles.root, className)}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" target="_blank">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" target="_blank">
        Terms of Service
      </a>{' '}
      apply.
    </div>
  ) : null;
};
