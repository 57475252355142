import { ComponentProps, FC } from 'react';

import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { FlexibleSectionManagerLegacy } from 'src/hygiene/sections/SectionManager/FlexibleSectionManagerLegacy';
import { getMicrocopy } from 'src/stores/microcopyStore';

import { Footer } from './Footer';

interface Props extends Omit<ComponentProps<typeof Footer>, 'content'> {}

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const FooterLegacy: FC<Props> = (props) => {
  const content = {
    copyright: getMicrocopy(MICROCOPY.GENERAL_FOOTER_COPYRIGHT, false, {
      year: `${new Date().getFullYear()}`,
    }),
    supportTitle: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_TITLE),
    supportContent: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_CONTENT),
    buttonEmailLabel: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL),
    buttonEmailValue: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE)?.toString() ?? '',
    buttonPhoneLabel: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL),
    buttonPhoneValue: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE)?.toString() ?? '',
    buttonMessageLabel: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL),
    buttonMessageValue: getMicrocopy(MICROCOPY.GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE)?.toString() ?? '',
  };

  return (
    <Footer
      {...props}
      content={content}
      awardsSection={
        !!props.awardsSectionData && (
          <FlexibleSectionManagerLegacy flexibleSection={props?.awardsSectionData?.sectionPageFlexibleSection} />
        )
      }
    />
  );
};
