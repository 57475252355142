import { FC } from 'react';

import classNames from 'classnames';
import { Image } from 'src/general/components/Image/Image';
import { FindACar } from 'src/hygiene/components/FindACar/FindACar';
import { ValueProps } from 'src/hygiene/components/ValueProps/ValueProps';
import { FlexibleSection } from '../flexibleContent.type';
import styles from './HeroHomepage.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    valueProps?: {
      imageUrl: string;
      title: string;
      description?: string;
    }[];
  };
}

export const HeroHomepage: FC<Props> = ({ sectionData }) => (
  <section className={classNames(styles.root, 'header-homepage', 'header-transparent')}>
    <header className={styles.header}>
      {/* Reads better for screen readers and SEO */}
      <h1 className="sr-only">Buy or sell a pre-owned car.</h1>
      <h2 className="sr-only">The way everyone deserves.</h2>
      <div aria-hidden="true">
        <span className={styles.word}>Buy or sell a</span>&nbsp;
        <Image
          src="https://img.carma.com.au/ui-assets/HeroHomepage/car-01.png"
          alt=""
          width={266}
          height={119}
          priority
          className={classNames(styles.car, styles.car1)}
        />
        <br />
        <Image
          src="https://img.carma.com.au/ui-assets/HeroHomepage/car-02.png"
          alt=""
          width={404}
          height={188}
          priority
          className={classNames(styles.car, styles.car2)}
        />
        &nbsp;
        <span className={styles.word}>pre-owned</span>
        <br />
        <span className={styles.word}>car.</span>&nbsp;
        <Image
          src="https://img.carma.com.au/ui-assets/HeroHomepage/car-03.png"
          alt=""
          width={402}
          height={183}
          priority
          className={classNames(styles.car, styles.car3)}
        />
        &nbsp;
        <span className={styles.subtitle}>
          <span className={styles.subtitleWord}>The way everyone</span>
          <br />
          <span className={styles.subtitleWord}>deserves.</span>
        </span>
      </div>
    </header>
    <div className={styles.content}>
      <FindACar />
    </div>
    <footer className={styles.footer}>
      <ValueProps valueProps={sectionData.valueProps} />
    </footer>
  </section>
);
