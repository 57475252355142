import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { Carousel } from './Carousel';
import styles from './CarouselSection.module.scss';

type Props = ComponentProps<typeof Carousel>;

/**
 * Carousel intended to be used within a page section
 *
 * __Please note:__ The direct parent element is expected to implement the SCSS mixin `sectionHorizontalSpacing()`
 */
export const CarouselSection: FC<Props> = ({ className, ...props }) => (
  <Carousel {...props} className={classNames(styles.root, className)} />
);
