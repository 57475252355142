import { FC } from 'react';

import { Image } from 'src/general/components/Image/Image';

type IconProps = { src: string; alt: string; width: number; height: number };

const get_url_extension = (url: string) => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
};

export const FinanceLogoIcon: FC<IconProps> = (props) => {
  const { src, alt } = props;
  if (get_url_extension(src) === 'svg') {
    return <object type="image/svg+xml" data={src}></object>;
  } else {
    return <Image {...props} alt={alt} />;
  }
};
