import { AxiosError } from 'axios';

import { carmaClientLegacy } from 'src/services/instance/carmaClientLegacy';

export interface ProductEnquiryPayload {
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  // eslint-disable-next-line camelcase
  email: string;
  // eslint-disable-next-line camelcase
  phone: string;
  // eslint-disable-next-line camelcase
  message: string;
  // eslint-disable-next-line camelcase
  vehicle_id: string;
  // eslint-disable-next-line camelcase
  recaptcha_token?: string;
  // eslint-disable-next-line camelcase
  news_and_marketing: boolean;
  // eslint-disable-next-line camelcase
  tradein_intent: boolean;
  // eslint-disable-next-line camelcase
  finance_interest: boolean;
  // eslint-disable-next-line camelcase
  session_source?: string;
  // eslint-disable-next-line camelcase
  session_medium?: string;
  // eslint-disable-next-line camelcase
  session_campaign?: string;
}

export interface FinanceEnquiryPayload {
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  // eslint-disable-next-line camelcase
  email: string;
  // eslint-disable-next-line camelcase
  phone: string;
  // eslint-disable-next-line camelcase
  message: string;
  // eslint-disable-next-line camelcase
  vehicle_id?: string;
  // eslint-disable-next-line camelcase
  deposit_amount?: number;
  // eslint-disable-next-line camelcase
  interest_rate?: number;
  // eslint-disable-next-line camelcase
  purchase_price?: number;
  // eslint-disable-next-line camelcase
  term_of_loan?: number;
  // eslint-disable-next-line camelcase
  weekly_payment?: number;
}

export interface LocationEnquiryPayload {
  // eslint-disable-next-line camelcase
  email: string;
  // eslint-disable-next-line camelcase
  post_code: string;
  // eslint-disable-next-line camelcase
  suburb: string;
  // eslint-disable-next-line camelcase
  state: string;
}

export const sendProductEnquiry = async (data: ProductEnquiryPayload) => {
  try {
    return await carmaClientLegacy.post('/enquiry', data);
  } catch (errorResponse) {
    const { response } = errorResponse as AxiosError<unknown, any>;
    return response;
  }
};

export const sendFinanceEnquiry = async (data: FinanceEnquiryPayload) => {
  try {
    return await carmaClientLegacy.post('/enquiry/finance', data);
  } catch (errorResponse) {
    const { response } = errorResponse as AxiosError<unknown, any>;
    return response;
  }
};

export const sendLocationEnquiry = async (data: LocationEnquiryPayload) => {
  const response = await carmaClientLegacy.post('/enquiry/location', data);
  return response.data;
};
