import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePathname } from 'next/navigation';
import { ComponentProps, FC, ReactNode } from 'react';

import { Button } from 'src/general/components/Button/Button';
import { CTAButton } from 'src/general/components/Button/CTAButton';
import { FormikInput } from 'src/general/components/forms/FormikInput/FormikInput';
import { formatNumber } from 'src/general/helpers/formatNumber';
import { FinanceCalculatorSlider } from 'src/hygiene/components/FinanceCalculatorSlider/FinanceCalculatorSlider';
import { DataLayerFinanceCalculatorEvent, pushToDataLayerFinanceCalculator } from 'src/utils/pushToDataLayer';

import { calculatePrincipal } from '../../FinanceCalculator.helper';
import { FinanceEnquiryFormValues } from '../../FinanceCalculator.type';
import styles from './WhatCanIAfford.module.scss';

type WhatCanIAffordProps = {
  loanTermsArray: number[];
  interestRateRange: Omit<ComponentProps<typeof FinanceCalculatorSlider>, 'formikFieldName'>;
  values: FinanceEnquiryFormValues;
  setFieldValue: FormikProps<FinanceEnquiryFormValues>['setFieldValue'];
  microcopy: {
    hygieneFinanceCalculatorTitle: ReactNode;
    hygieneFinanceCalculatorInputLabel1: ReactNode;
    hygieneFinanceCalculatorInputLabel2: ReactNode;
    hygieneFinanceCalculatorLoanTitle: ReactNode;
    hygieneFinanceCalculatorLoanTitleEstimatedBorrowAmount: ReactNode;
    personalizedQuoteFinanceCalculatorCta: ReactNode;
  };
};

export const WhatCanIAfford: FC<WhatCanIAffordProps> = ({
  loanTermsArray,
  interestRateRange,
  values,
  setFieldValue,
  microcopy,
}) => {
  const pathName = usePathname();

  const flags = useFlags();

  return (
    <div className={styles.layout}>
      <div>
        <div className={styles.title}>{microcopy.hygieneFinanceCalculatorTitle}</div>
        <div className={styles.inputWrapper}>
          <FormikInput
            name="weeklyPayment"
            iconLeft="$"
            label={microcopy.hygieneFinanceCalculatorInputLabel1}
            type="number"
            inputProps={{ min: 0, max: 5000 }}
            onTrackingEvent={(value: string) => {
              pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_weekly_payment, {
                tab: 'WHAT_CAN_I_AFFORD',
                value,
              });
            }}
          />
          <FormikInput
            name="depositAmount"
            iconLeft="$"
            label={microcopy.hygieneFinanceCalculatorInputLabel2}
            type="number"
            inputProps={{ min: 0, max: 250000 }}
            onTrackingEvent={(value: string) => {
              pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_deposit_amount, {
                tab: 'WHAT_CAN_I_AFFORD',
                value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.loanBlock}>
        <div className={styles.title}>{microcopy.hygieneFinanceCalculatorLoanTitle}</div>
        <div className={styles.buttonsContainer}>
          {loanTermsArray.map((item) => (
            <Button
              key={item.toString()}
              variant="secondary"
              size="small"
              type="button"
              className={classNames(styles.termButton, {
                [styles.active]: item === values.termOfLoan,
              })}
              onClick={() => {
                setFieldValue('termOfLoan', item);
                pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_term_of_loan, {
                  tab: 'WHAT_CAN_I_AFFORD',
                  value: item,
                });
              }}
              fullWidth
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      <FinanceCalculatorSlider
        minRate={interestRateRange.minRate}
        maxRate={interestRateRange.maxRate}
        formikFieldName="interestRate"
        onTrackingEvent={(value) =>
          pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.edit_interest_rate, {
            tab: 'WHAT_CAN_I_AFFORD',
            value,
          })
        }
      />
      <div className={styles.paymentBlock}>
        <div>
          <div className={styles.normalText}>{microcopy.hygieneFinanceCalculatorLoanTitleEstimatedBorrowAmount}</div>
          <div className={styles.fee}>
            {formatNumber(
              calculatePrincipal(
                values.weeklyPayment || 0,
                values.interestRate,
                values.termOfLoan,
                parseInt(`${values.depositAmount}`, 10),
              ),
              {
                prepend: '$',
                append: '*',
              },
            )}
          </div>
        </div>
        {flags?.personalisedQuoteForm && (
          <CTAButton
            size="medium"
            variant="accent"
            {...{
              label: microcopy.personalizedQuoteFinanceCalculatorCta,
              buttonType: 'link',
              href: `/personalised-quote?purchasePrice=${values.purchasePrice}&depositAmount=${values.depositAmount}&origin=${pathName}`,
            }}
          />
        )}
      </div>
    </div>
  );
};
