'use client';

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { FC, ReactNode, useCallback, useState } from 'react';

import { IconCaretDown } from 'src/general/Icons/IconCaretDown';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import styles from './Accordion.module.scss';

const TRANSITION_DURATION = parseInt(styles['transitionDuration'], 10);

export interface AccordionItem {
  id: string;
  header: ReactNode;
  content: ReactNode;
}

interface AccordionProps {
  className?: string;
  items: AccordionItem[];
  /** Name used to track GTM events */
  trackingName?: string;
  initialOpenIndexes?: number[];
}

export const Accordion: FC<AccordionProps> = ({ className, items, trackingName, initialOpenIndexes = [] }) => (
  <div className={classNames(styles.root, className)}>
    {items.map(({ id, header, content }, index) => {
      const initialOpenIndex = initialOpenIndexes.findIndex((value) => value === index);

      return (
        <AccordionItem
          key={id}
          id={id}
          header={header}
          content={content}
          index={index}
          trackingName={trackingName}
          initialOpen={initialOpenIndex >= 0}
        />
      );
    })}
  </div>
);

interface AccordionItemProps extends AccordionItem, Pick<AccordionProps, 'trackingName'> {
  index: number;
  initialOpen?: boolean;
}

const CSSTransition = dynamic(() => import('react-transition-group').then((mod) => mod.CSSTransition), {
  ssr: false,
});

const AccordionItem: FC<AccordionItemProps> = ({ id, header, content, trackingName, index, initialOpen = false }) => {
  const idHeader = `accordion-${id}-header`;

  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  const handleClick = useCallback(() => {
    const newIsOpen = !isOpen;
    if (trackingName) {
      pushToDataLayer({ event: 'accordion_click', name: trackingName, index, open: isOpen });
    }
    setIsOpen(newIsOpen);
  }, [trackingName, index, isOpen, setIsOpen]);

  return (
    <div className={classNames(styles.accordion, 'accordion', { [styles.expanded]: isOpen, expanded: isOpen })}>
      <button
        className={classNames(styles.accordionTrigger, 'accordionTrigger')}
        id={idHeader}
        onClick={handleClick}
        aria-expanded={isOpen}
        data-testid={`accordion-${id}`}
      >
        <span className={classNames(styles.accordionHeader, 'accordionHeader', 'c-fw-bold', ' c-fs-body1')}>
          {header}
        </span>
        <span className={classNames(styles.accordionIcon)}>
          <IconCaretDown />
        </span>
      </button>
      <div className={classNames(styles.accordionContentWrapper)} aria-labelledby={idHeader}>
        <CSSTransition
          in={!!isOpen}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
          }}
          appear
          unmountOnExit
          timeout={TRANSITION_DURATION}
        >
          <div className={classNames(styles.accordionContent, 'accordionContent', 'c-fw-normal', 'c-fs-body2')}>
            {content}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
