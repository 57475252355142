import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconInfoCircleEmpty: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path d="M7.6 5.833a.4.4 0 1 0 .8 0 .4.4 0 0 0-.8 0ZM7.6 11.567V7.9h-.667v-.8H8.4v4.467h-.8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-.8 0a5.2 5.2 0 1 1-10.4 0 5.2 5.2 0 0 1 10.4 0Z"
    />
  </Icon>
);
