import { ComponentProps, FC } from 'react';
import { ImageObject, WithContext } from 'schema-dts';

import { JsonLD } from 'src/general/components/JsonLD/JsonLD';

import { InlineImage } from './InlineImage';

interface Props extends Pick<ComponentProps<typeof InlineImage>, 'sectionData'> {}

export const InlineImageJsonLD: FC<Props> = ({ sectionData: { title, subTitle, images } }) => {
  const jsonLD: WithContext<ImageObject> = {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    name: title,
    description: subTitle,
    image: images.map((image) => ({
      '@type': 'ImageObject',
      url: image.url,
      description: image.description,
    })),
  };
  return <JsonLD jsonLD={jsonLD} />;
};
