'use client';

import dynamic from 'next/dynamic';
import { FC, useState } from 'react';

import { Button } from 'src/general/components/Button/Button';

import styles from './OptInSection.module.scss';

const CarEnquiryForm = dynamic(
  () => import('src/pdp/components/CarEnquiryForm/CarEnquiryForm').then((mod) => mod.CarEnquiryForm),
  { ssr: false },
);

type Props = {
  buttonLabel: string;
};

export const OptInForm: FC<Props> = ({ buttonLabel }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  // Need to implement this logic in this component
  const continueBrowsingUrl = '';
  const onStartFormProcess = () => {};
  const onSubmit = async () => '';

  return (
    <>
      <footer className={styles.footer}>
        <Button onClick={onOpen}>{buttonLabel}</Button>
      </footer>

      <CarEnquiryForm
        continueBrowsingUrl={continueBrowsingUrl}
        onStartFormProcess={onStartFormProcess}
        onSubmit={onSubmit}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
