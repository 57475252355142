import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconPhone: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.863 21.768a7.333 7.333 0 0 0 0-10.37 2.333 2.333 0 0 0-3.3 0L27.09 12.87a1.917 1.917 0 0 0 0 2.71 3.917 3.917 0 0 1 0 5.54l-5.303 5.303a3.917 3.917 0 0 1-5.54 0 1.917 1.917 0 0 0-2.71 0l-1.473 1.473a2.333 2.333 0 0 0 0 3.3 7.333 7.333 0 0 0 10.371 0l9.428-9.429Zm1.414-11.785a9.333 9.333 0 0 1 0 13.2L23.85 32.61a9.333 9.333 0 0 1-13.2 0 4.333 4.333 0 0 1 0-6.129l1.474-1.473a3.917 3.917 0 0 1 5.54 0 1.917 1.917 0 0 0 2.71 0l5.303-5.303a1.917 1.917 0 0 0 0-2.71 3.917 3.917 0 0 1 0-5.54l1.473-1.473a4.333 4.333 0 0 1 6.128 0ZM21.229 13.68l-.966.26a8 8 0 0 0-5.657 5.656l-.259.966-1.932-.518.259-.966a10 10 0 0 1 7.071-7.07l.966-.26.518 1.932Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.667 8.667A11.333 11.333 0 0 0 9.333 20v1h-2v-1A13.333 13.333 0 0 1 20.667 6.667h1v2h-1Z"
    />
  </Icon>
);
