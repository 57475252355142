import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { FlexibleSection } from 'src/hygiene/sections/flexible-content/flexibleContent.type';

import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './HowItWorks.module.scss';

interface Step {
  title: string;
  content: string;
  icon: string;
}

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    heroImageDesktop: string;
    heroImageMobile: string;
    steps: Step[];
  };
}

export const HowItWorks: FC<Props> = ({ sectionData }) => {
  const { title, heroImageMobile, heroImageDesktop, steps } = sectionData;
  return (
    <div className={classNames(styles.root, getSectionClasses('sand'))}>
      <div className={styles.grid}>
        <Image className={styles.heroImageDesktop} src={heroImageDesktop} alt={'heroImage'} width={335} height={208} />
        <Image className={styles.heroImageMobile} src={heroImageMobile} alt={'heroImage'} width={335} height={208} />
        <div className={styles.stepsColumn}>
          <p className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</p>
          <div className={styles.steps}>
            {steps.map((step, index) => (
              <div key={step.title} className={classNames(styles.step)}>
                <div className={styles.stepIconContainer}>
                  <div className={styles.stepIcon}>
                    <Image src={step.icon} alt={'icon'} width={32} height={32} />
                  </div>
                  {index !== steps.length - 1 && <div className={styles.stepConnector} />}
                </div>
                <div>
                  <p className={classNames(styles.stepTitle, 'c-fw-bold', 'c-fs-body1')}>{step.title}</p>
                  <p className={classNames(styles.stepContent, 'c-fw-normal', 'c-fs-body1')}>{step.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
