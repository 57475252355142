import { Mark, Slider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useRef } from 'react';

import styles from 'src/checkout/components/FinanceQuoteTermSlider/FinanceQuoteTermSlider.module.scss';
import { FormikInput } from 'src/general/components/forms/FormikInput/FormikInput';
import { FinanceEnquiryFormValues } from 'src/hygiene/sections/FinanceCalculator/FinanceCalculator.type';

import classes from './FinanceCalculatorSlider.module.scss';
import classNames from 'classnames';

type FinanceCalculatorSliderProps = {
  formikFieldName: keyof FinanceEnquiryFormValues;
  minRate: number;
  maxRate: number;
  onTrackingEvent?: (value: any) => void;
};

export const FinanceCalculatorSlider: FC<FinanceCalculatorSliderProps> = ({
  formikFieldName,
  minRate,
  maxRate,
  onTrackingEvent,
}) => {
  const debounceRef = useRef(
    debounce((value) => {
      onTrackingEvent?.(value);
    }, 500),
  );
  const { values, setFieldValue } = useFormikContext<FinanceEnquiryFormValues>();
  const ratesArray = Array.from(
    { length: Math.ceil(maxRate) - Math.floor(minRate) + 1 },
    (_, i) => i + Math.floor(minRate),
  );
  const marks: Mark[] = [...new Set(ratesArray)]
    .sort((a, b) => a - b)
    .map((item): Mark => ({ label: item, value: item }));
  const availableRates = marks.length !== 0;
  const minValue = availableRates ? marks[0].value : undefined;
  const maxValue = availableRates ? marks[marks.length - 1].value : undefined;
  const onChange = (_: ChangeEvent<{}>, value: number | number[]) => {
    setFieldValue(formikFieldName, Array.isArray(value) ? value[0] : value);
    debounceRef.current(Array.isArray(value) ? value[0] : value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <div>
          <div className={classes.title}>Interest rate</div>
          <div className={classNames(classes.description, 'c-fw-normal', 'c-fs-caption')}>
            Slide between {minValue}% and {maxValue}%
          </div>
        </div>
        <div className={classes.rateInput}>
          <FormikInput
            name={formikFieldName}
            iconRight="%"
            labelOutside
            inputMode="numeric"
            inputProps={{ min: minValue, max: maxValue, step: 0.01 }}
          />
        </div>
      </div>
      <div className={styles.slider}>
        <Slider
          disabled={!ratesArray.length}
          value={values[formikFieldName]! as number}
          aria-label="Interest rate"
          valueLabelDisplay="off"
          step={1}
          marks={marks}
          min={minValue}
          max={maxValue}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
