'use client';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC } from 'react';
import useSWRImmutable from 'swr/immutable';

import { fetchVehicleFilters } from 'src/data/ProductApi/ProductApiListVehicles';
import { Button } from 'src/general/components/Button/Button';
import { Divider } from 'src/general/components/Divider/Divider';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import { formatNumberComma } from 'src/utils/utils';

import { getPLPCarData } from 'src/services/CarService';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import styles from './FindACar.module.scss';

const SearchWidget = dynamic(() => import('src/general/components/HeaderMenu/SearchWidget/SearchWidget'), {
  ssr: false,
});
const KeywordSearch = dynamic(
  () => import('src/general/components/KeywordSearch/KeywordSearch').then((mod) => mod.KeywordSearch),
  {
    ssr: false,
  },
);

interface FindACarProps {
  className?: string;
}

export const FindACar: FC<FindACarProps> = ({ className = '' }) => {
  const { data: vehicleFilters } = useSWRImmutable(
    ['/vehicle-filters', { includeMakesWithNoResult: false }],
    ([_, props]) => fetchVehicleFilters(props),
  );
  const { data: productList } = useSWRImmutable<ProductListDataPayload>(
    {
      pageSize: 1, // Minimum valid pageSize
    },
    getPLPCarData,
  );

  const total = productList?.total ?? 0;

  return (
    <aside className={`${styles.root} ${className}`}>
      <div className={styles.search}>
        <KeywordSearch vehicleFilters={vehicleFilters} className={styles.searchForm} />
        <SearchWidget
          className={styles.searchTrigger}
          keywordSearch={<KeywordSearch isMobile vehicleFilters={vehicleFilters} />}
        />
      </div>
      <Divider>or</Divider>
      <Button
        data-testid="HYGIENE_HOMEPAGE_SEARCH_ALL_CARS"
        href="/used-cars"
        fullWidth
        variant="accent"
        className={styles.button}
        onClick={() => {
          pushToDataLayer({
            event: 'search_click_browse_all_cars',
            value: total,
          });
        }}
      >
        Browse all {total > 0 ? `${formatNumberComma(total)}` : ''} cars
      </Button>
      <footer className={styles.footer}>
        <span className="c-fs-body2 c-fw-normal">
          Looking to sell your car?{' '}
          <Link href="/sell-or-trade-in-my-car" className="c-fw-bold" prefetch={false}>
            Get a valuation
          </Link>
        </span>
      </footer>
    </aside>
  );
};
