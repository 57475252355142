import { FC } from 'react';

import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { REVIEW_SOURCE } from 'src/hygiene/sections/flexible-content/CustomerReviews/CustomerReviews.types';
import { getMicrocopy } from 'src/stores/microcopyStore';

import { ReviewSummary } from './ReviewSummary';
import { ReviewSummaryProps } from './ReviewSummary.types';

export const ReviewSummaryLegacy: FC<ReviewSummaryProps> = (props) => {
  const ctaLabel = getMicrocopy(MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_CTA_LABEL);
  const counts = {
    [REVIEW_SOURCE.GOOGLE]: getMicrocopy(MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_GOOGLE_COUNT)?.toString() ?? '',
    [REVIEW_SOURCE.PRODUCT_REVIEW]:
      getMicrocopy(MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_COUNT)?.toString() ?? '',
  };
  const averageRatings = {
    [REVIEW_SOURCE.GOOGLE]: getMicrocopy(MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_GOOGLE_AVERAGE)?.toString() ?? '',
    [REVIEW_SOURCE.PRODUCT_REVIEW]:
      getMicrocopy(MICROCOPY.CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_AVERAGE)?.toString() ?? '',
  };
  return <ReviewSummary {...props} ctaLabel={ctaLabel} counts={counts} averageRatings={averageRatings} />;
};
