import { ComponentProps, FC } from 'react';

import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { ReviewCard } from 'src/hygiene/components/flexible-content/ReviewCard/ReviewCard';
import { ReviewSummary } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummary';
import { ReviewSummaryProps } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummary.types';

import classNames from 'classnames';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './CustomerReviews.module.scss';

interface Props extends FlexibleSection {
  id?: string;
  sectionData: {
    title: string;
    reviews: Array<ComponentProps<typeof ReviewCard>>;
    withReviewSummary: boolean;
    reviewSummaryLinks: ComponentProps<typeof ReviewSummary>['sectionData'];
  };
  ReviewSummaryComponent?: FC<ReviewSummaryProps>;
}

export const CustomerReviews: FC<Props> = ({
  id = 'customerReviews',
  sectionData: { title, reviews, withReviewSummary, reviewSummaryLinks },
  sectionBackground,
  ReviewSummaryComponent,
}) => (
  <section id={id} className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    {!!title && (
      <header className={styles.header}>
        <h2 className="c-fs-h4">{title}</h2>
      </header>
    )}
    <CarouselSection tiles={reviews.map((item) => ({ id: item.customerName, tile: <ReviewCard {...item} /> }))} />
    {withReviewSummary && ReviewSummaryComponent && (
      <ReviewSummaryComponent className={styles.summary} sectionData={reviewSummaryLinks} />
    )}
  </section>
);
