import { ComponentProps, FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';

import { MarkdownCarousel } from './MarkdownCarousel';
import { MarkdownImage } from './MarkdownImage';
import { MarkdownInstagram } from './MarkdownInstagram';
import { MarkdownLink } from './MarkdownLink';
import { MarkdownVideo } from './MarkdownVideo';
import { Blockquote, Quote } from './Quote';
import { customDirective } from './customDirective';

type Props = Pick<ComponentProps<typeof ReactMarkdown>, 'className' | 'components' | 'children'>;

export const Markdown: FC<Props> = ({ className, components, children }) => (
  <ReactMarkdown
    className={className}
    remarkPlugins={[remarkDirective, customDirective]}
    components={useMemo(
      () => ({
        a: MarkdownLink,
        quote: Quote,
        img: MarkdownImage,
        video: MarkdownVideo,
        blockquote: Blockquote,
        instagram: MarkdownInstagram,
        carousel: MarkdownCarousel,
        ...components,
      }),
      [components],
    )}
  >
    {children}
  </ReactMarkdown>
);
