import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconDeliveryCar: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6664 25.3333C20.7312 25.3333 19.117 23.9591 18.7464 22.1333H13.2531C12.8824 23.9591 11.2682 25.3333 9.33307 25.3333C7.32163 25.3333 5.65695 23.8486 5.37512 21.9153L4.44953 21.4525C3.27508 20.8653 2.5332 19.6649 2.5332 18.3519V14.4379C2.5332 13.5184 2.89844 12.6367 3.54857 11.9866L7.3198 8.21531C7.96993 7.56519 8.85169 7.19995 9.77111 7.19995H16.3193C17.1684 7.19995 17.9879 7.51153 18.6225 8.0756L23.4939 12.4058C23.6655 12.5583 23.8637 12.6778 24.0786 12.7584L27.2171 13.9353C28.5702 14.4427 29.4665 15.7362 29.4665 17.1813V18.6666C29.4665 20.3778 28.2267 21.7994 26.5964 22.0822C26.2458 23.9334 24.6196 25.3333 22.6664 25.3333ZM16.3193 8.79995C16.7765 8.79995 17.2178 8.96772 17.5595 9.27145L21.229 12.5333H19.1997V14.1333H23.2285C23.3226 14.1786 23.4188 14.2198 23.5168 14.2565L26.6553 15.4335C27.3839 15.7067 27.8665 16.4032 27.8665 17.1813V18.6666C27.8665 19.4996 27.3209 20.2052 26.5675 20.4454C26.1637 18.6636 24.5704 17.3333 22.6664 17.3333C20.7312 17.3333 19.117 18.7075 18.7464 20.5333H13.2531C12.8824 18.7075 11.2682 17.3333 9.33307 17.3333C7.52174 17.3333 5.99161 18.5372 5.49928 20.1886L5.16507 20.0215C4.53267 19.7053 4.1332 19.0589 4.1332 18.3519V14.8H16.7999V13.2H12.1332V8.79995H16.3193ZM4.67994 13.1179C4.6533 13.1446 4.62755 13.1719 4.60271 13.2H10.5332V8.79995H9.77111C9.27604 8.79995 8.80124 8.99662 8.45117 9.34669L4.67994 13.1179ZM22.6664 23.7333C21.3409 23.7333 20.2664 22.6588 20.2664 21.3333C20.2664 20.0078 21.3409 18.9333 22.6664 18.9333C23.9919 18.9333 25.0664 20.0078 25.0664 21.3333C25.0664 22.6588 23.9919 23.7333 22.6664 23.7333ZM6.93307 21.3333C6.93307 22.6588 8.00759 23.7333 9.33307 23.7333C10.6586 23.7333 11.7331 22.6588 11.7331 21.3333C11.7331 20.0078 10.6586 18.9333 9.33307 18.9333C8.00759 18.9333 6.93307 20.0078 6.93307 21.3333Z"
    />
  </Icon>
);
