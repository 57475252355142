import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { ReviewSummary } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummary';
import { ReviewSummaryProps } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummary.types';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './CustomerReviewsScore.module.scss';

interface Props extends FlexibleSection {
  sectionData: ComponentProps<typeof ReviewSummary>['sectionData'];
  ReviewSummaryComponent?: FC<ReviewSummaryProps>;
}

export const CustomerReviewsScore: FC<Props> = ({ sectionData, ReviewSummaryComponent, sectionBackground }) =>
  ReviewSummaryComponent ? (
    <section className={classNames('CustomerReviewsScore', styles.root, getSectionClasses(sectionBackground))}>
      <ReviewSummaryComponent sectionData={sectionData} className={styles.reviews} />
    </section>
  ) : null;
