import { DocumentNode } from 'graphql';

import cmsGraphClient, { cmsGraphClientPreview } from 'src/services/instance/cmsGraphClient';

/**
 * Perform a query to Contentful
 * Use on the server side, only on getStaticProps or getStaticPaths
 */
export default async function contentfulQuery<T>(
  query: DocumentNode,
  variables?: { [key: string]: any },
  preview = false,
): Promise<T> {
  const result = await (!preview ? cmsGraphClient : cmsGraphClientPreview).query<T>({
    query,
    variables,
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  });
  return result.data;
}

const getGqlString = (doc: DocumentNode) => doc.loc && doc.loc.source.body;

export const contentfulQueryFetch = async <T>(
  query: DocumentNode,
  caching: {
    revalidate?: number;
    tags?: string[];
  },
  variables?: { [key: string]: any },
  preview = false,
): Promise<T> => {
  const contentfulRoot = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CMS_SPACE_ID}/environments/${process.env.REACT_APP_CMS_ENVIRONMENT}`;
  const result = await fetch(contentfulRoot, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${
        preview ? process.env.REACT_APP_CMS_PREVIEW_TOKEN : process.env.REACT_APP_CMS_DELIVERY_TOKEN
      }`,
    },
    body: JSON.stringify({
      query: getGqlString(query),
      variables,
    }),
    cache: preview ? 'no-cache' : undefined,
    next: preview ? undefined : caching,
  });

  if (!result.ok) {
    console.error(`Failed to fetch data ${contentfulRoot}, got:`);
    console.warn(await result.json());
    throw new Error(`Failed to fetch data ${contentfulRoot}`);
  }

  const parsedResult = await result.json();

  return parsedResult.data;
};
