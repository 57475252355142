import { ComponentProps } from 'react';
import * as Yup from 'yup';

import { regexMobilePhone } from 'src/constants';
import { CTAButton } from 'src/general/components/Button/CTAButton';
import { FinanceCalculatorSlider } from 'src/hygiene/components/FinanceCalculatorSlider/FinanceCalculatorSlider';

export interface FinanceCalculatorProps {
  button?: Partial<ComponentProps<typeof CTAButton>>;
  interestRateRange: Omit<ComponentProps<typeof FinanceCalculatorSlider>, 'formikFieldName'>;
  loanTerms: {
    min: number;
    max: number;
  };
  onSubmit: (values: any) => void;
}

const errorFirstNameRequired = 'Please enter your first name';
const errorLastNameRequired = 'Please enter your last name';
const errorEmailAddressRequired = 'Please enter your email address';
const errorInvalidEmailAddress = 'Please enter valid email address';
const errorPhoneNumber = 'Please enter a valid mobile number (must be an Australian mobile number)';

export const FinanceEnquiryFormValidation = Yup.object({
  weeklyPayment: Yup.number()
    .optional()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid value')
    .max(5000, 'Please enter a valid value'),
  purchasePrice: Yup.number()
    .optional()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid value')
    .max(250000, 'Please enter a valid value'),
  depositAmount: Yup.number()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid value')
    .max(250000, 'Please enter a valid value'),
  termOfLoan: Yup.number()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid value')
    .max(250000, 'Please enter a valid value'),
  interestRate: Yup.number()
    .typeError('Please enter a number')
    .min(6, 'Please enter a valid value')
    .max(15, 'Please enter a valid value'),
  message: Yup.string(),
  firstName: Yup.string().required(errorFirstNameRequired),
  lastName: Yup.string().required(errorLastNameRequired),
  emailAddress: Yup.string().email(errorInvalidEmailAddress).required(errorEmailAddressRequired),
  phoneNumber: Yup.string().matches(regexMobilePhone, errorPhoneNumber),
});

export type FinanceEnquiryFormValues = ReturnType<typeof FinanceEnquiryFormValidation.validateSync>;
