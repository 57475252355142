'use client';

import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { IconCircleExclamation } from 'src/general/Icons/IconCircleExclamation';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/general/components/Tooltip/Tooltip';
import { FinanceLogoIcon as LogoIcon } from 'src/hygiene/templates/FinanceLogoIcon/FinanceLogoIcon';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './TableSection.module.scss';

export interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    description: string;
    tablesItems: TablesItems[];
  };
}

interface TablesItemsProps {
  tablesItems: TablesItems[];
}

type TablesItems = {
  mainHeader: {
    mainHeaderTitle: string;
    mainHeaderSubtitle: string;
    backgroundColor: string;
    fontColor: string;
  };
  primaryLineItems: PrimaryLineItems[];
  secondaryHeader: {
    secondaryHeaderTitle: string;
  };
  secondaryLineItem: SecondaryLineItem[];
};

type PrimaryLineItems = {
  primaryCategoryTitle: string;
  primaryItemValue: string;
  primaryItemIcon: string;
  primaryItemTooltip: string;
};

type SecondaryLineItem = {
  secondaryCategoryTitle: string;
  secondaryItemIcon: string;
  secondaryTooltip: string;
};

export const TableSection: FC<Props> = ({ sectionData: { description, tablesItems, title }, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    <header className={styles.header}>
      <h2 className={classNames('c-fs-h4', 'c-fw-bold')}>{title}</h2>
      <p className={classNames(styles.subTitle, 'c-fw-normal', 'c-fs-body1')}>{description}</p>
    </header>
    <TableSectionContent tablesItems={tablesItems} />
  </section>
);

const TableSectionContent: FC<TablesItemsProps> = ({ tablesItems }) => {
  const renderBreakdownTravel = (values: SecondaryLineItem[] | undefined): ReactNode => {
    if (values && values.length > 0) {
      return values
        .filter((item) => item.secondaryCategoryTitle?.trim())
        .map((item) => (
          <div className={styles.breakDownItem} key={item.secondaryCategoryTitle}>
            <div className={styles.title}>
              <div className={styles.iconBlock}>
                <LogoIcon src={item.secondaryItemIcon} alt={item.secondaryItemIcon} width={20} height={20} />
              </div>
              <p className={classNames('c-fw-normal', 'c-fs-body2')}>{item.secondaryCategoryTitle}</p>
            </div>
            {item.secondaryTooltip && (
              <Tooltip>
                <TooltipTrigger>
                  <IconCircleExclamation className={styles.iconExclamation} />
                </TooltipTrigger>
                <TooltipContent>
                  <div className={styles.tooltip}>
                    <p className={styles.headline}>{item.secondaryCategoryTitle}</p>
                    <p className={styles.description}>{item.secondaryTooltip}</p>
                  </div>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        ));
    }
  };

  return (
    <section>
      <div className={styles.contentWrapper}>
        {tablesItems.map(({ mainHeader, primaryLineItems, secondaryHeader, secondaryLineItem }) => (
          <>
            <div
              className={classNames(
                styles.baseHeader,
                styles[mainHeader.fontColor],
                styles[mainHeader.backgroundColor],
              )}
            >
              <h3 className={classNames(styles.title, 'c-fs-h6', 'c-fw-bold')}>{mainHeader.mainHeaderTitle}</h3>
              <p className={classNames(styles.subTitle, 'c-fw-normal', 'c-fs-body2')}>
                {mainHeader.mainHeaderSubtitle}
              </p>
            </div>
            <div className={styles.bodyContent}>
              <div className={styles.itemsWrapper}>
                {primaryLineItems
                  .filter((item) => item.primaryCategoryTitle?.trim())
                  .map((item) => (
                    <div className={styles.itemContent} key={item.primaryCategoryTitle}>
                      <div className={styles.headerItem}>
                        <div className={styles.title}>
                          <div className={styles.iconBlock}>
                            {item.primaryItemIcon && (
                              <LogoIcon src={item.primaryItemIcon} alt={item.primaryItemIcon} width={20} height={20} />
                            )}
                          </div>
                          <p className={classNames('c-fw-bold', 'c-fs-body2')}>{item.primaryCategoryTitle}</p>
                        </div>
                        {item.primaryItemTooltip && (
                          <Tooltip>
                            <TooltipTrigger className={styles.iconWrapper}>
                              <IconCircleExclamation height={32} className={styles.iconExclamation} />
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className={styles.tooltip}>
                                <p className={classNames(styles.headline, 'c-fw-bold', 'c-fs-caption')}>
                                  {item.primaryCategoryTitle}
                                </p>
                                <p className={classNames(styles.description, 'c-fw-normal', 'c-fs-caption')}>
                                  {item.primaryItemTooltip}
                                </p>
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </div>
                      <p className={styles.valueItem}>{item.primaryItemValue}</p>
                    </div>
                  ))}
              </div>
              <div className={styles.breakDownContent}>
                <p className={classNames(styles.breakDownHeader, 'c-fw-ultrabold', 'c-fs-overline')}>
                  {secondaryHeader.secondaryHeaderTitle}
                </p>
                {renderBreakdownTravel(secondaryLineItem)}
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};
