import classNames from 'classnames';
import { FC } from 'react';

import { ProductCardVariant } from 'src/constants';
import { Button } from 'src/general/components/Button/Button';
import { Divider } from 'src/general/components/Divider/Divider';
import { Skeleton } from 'src/general/components/Skeleton/Skeleton';

import styles from './ProductCard.module.scss';

interface Props {
  variant?: ProductCardVariant;
}

export const ProductCardLoading: FC<Props> = ({ variant = 'plp' }) => (
  <li className={classNames(styles.root, styles[variant], styles.loading)}>
    <div className={classNames(styles.image, styles.imagePlaceholder)} />
    <p className={styles.model}>
      <Skeleton />
    </p>
    <p className={styles.variant}>
      <Skeleton size={50} />
    </p>
    <p className={styles.details}>
      <Skeleton size={40} />
      &ensp;•&ensp;
      <Skeleton size={40} />
    </p>
    <aside className={styles.controls}>{/* Empty */}</aside>
    <footer className={styles.footer}>
      <p className={styles.price}>
        <Skeleton size={50} />
      </p>
      <p className={styles.repayment}>
        <Skeleton />
      </p>
      {variant === 'watchlist' && (
        <>
          <Divider className={styles.footerSeparator} />
          <div className={styles.footerActions}>
            <Button variant="secondary" size="small" fullWidth disabled>
              &nbsp;
            </Button>
            <Button variant="primary" size="small" fullWidth disabled>
              &nbsp;
            </Button>
          </div>
        </>
      )}
    </footer>
  </li>
);
