import dynamic from 'next/dynamic';
import { ComponentProps, FC } from 'react';

const SectionLayout = dynamic(() => import('../SectionLayout/SectionLayout').then((mod) => mod.SectionLayout));

export const Callout: FC<ComponentProps<typeof SectionLayout>> = ({
  sectionData,
  linkedEntries,
  sectionBackground,
}) => (
  <SectionLayout
    sectionData={{ ...sectionData, isCallout: true }}
    linkedEntries={linkedEntries}
    sectionBackground={sectionBackground}
  />
);
