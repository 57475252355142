'use client';

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';

import { IconInfoCircleEmpty } from 'src/general/Icons/IconInfoCircleEmpty';

import classNames from 'classnames';
import styles from './ProductCardRepaymentsTooltip.module.scss';

interface Props {
  weeklyRepayment: string;
  defaultRate: string;
}

const FloatingPortal = dynamic(() => import('@floating-ui/react').then((mod) => mod.FloatingPortal), {
  ssr: false,
});

export const ProductCardRepaymentsTooltip: FC<Props> = ({ weeklyRepayment, defaultRate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()} className={styles.root} title="More info">
        <IconInfoCircleEmpty className={styles.icon} />
      </button>
      {isOpen && (
        <FloatingPortal>
          <div className={styles.tooltip} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <h4 className={classNames(styles.title, 'c-fs-caption', 'c-fw-bold')}>
              {' '}
              Weekly payment of {weeklyRepayment}
            </h4>
            <div className="c-fs-caption">
              The weekly payment of {weeklyRepayment} is based on payment over a 5 year term at an average rate of{' '}
              {defaultRate} p.a. Fees and charges apply^
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
