import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { Image } from 'src/general/components/Image/Image';
import { PromoPill } from 'src/general/components/PromoPill/PromoPill';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './VerticalMediaAndContent.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    subTitle?: string;
    content: string;
    tagLabel?: string;
    tagIcon?: string;
    mobileBackgroundImgUrl: string;
    desktopBackgroundImgUrl: string;
  };
}

export const VerticalMediaAndContent: FC<Props> = ({
  sectionData: { tagLabel, tagIcon, title, subTitle, content, mobileBackgroundImgUrl, desktopBackgroundImgUrl },
  sectionBackground,
}) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    {tagLabel && (
      <div className={styles.tagLabelContainer}>
        <PromoPill
          promoPillData={[
            {
              key: tagLabel,
              copy: tagLabel,
              backgroundColor: '#EBF2F5',
              color: 'rgba(21, 13, 44, 0.88)',
              icon: tagIcon,
            },
          ]}
          className={styles.tagLabel}
        />
      </div>
    )}
    <h4 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h4>
    {!isEmpty(subTitle) && <h6 className={classNames(styles.subTitle, 'c-fw-normal', 'c-fs-h5')}>{subTitle}</h6>}
    <ReactMarkdown className={classNames(styles.content, 'c-fw-normal', 'c-fs-body1')}>{content}</ReactMarkdown>
    <div className={classNames(styles.image, styles.imageMobile)}>
      <Image src={mobileBackgroundImgUrl} alt="" sizes="100vw" width={343} height={343} />
    </div>
    <div className={classNames(styles.image, styles.imageDesktop)}>
      <Image src={desktopBackgroundImgUrl} sizes="100vw" alt="" width={1064} height={368} />
    </div>
  </section>
);
