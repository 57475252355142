import { ContentfulEntryId } from 'src/constants';
import aboutCarmaHelper from 'src/utils/aboutCarmaHelper';

import { listAllCarmaErrorPages } from '../Contentful/ContentfulGetCarmaErrorListing';
import { GetFooterQuery, getFooterData } from '../Contentful/ContentfulGetFooter';
import { getGlobalSettings } from '../Contentful/ContentfulGetGlobalSettings';
import { listAllMicrocopyPages } from '../Contentful/ContentfulGetMicrocopyListing';

/**
 * Retrieve global data for the static page generation
 *
 * This function is intended to be used within a getStaticProps function
 */
export async function getPageDefaults(preview = false) {
  const [footerData, contentfulMicrocopy, contentfulCarmaError, globalSettings] = await Promise.all([
    getFooterData(ContentfulEntryId.FOOTER, preview),
    listAllMicrocopyPages(preview),
    listAllCarmaErrorPages(preview),
    getGlobalSettings(ContentfulEntryId.GLOBAL_SETTINGS, preview),
  ]);

  return {
    footerData,
    contentfulMicrocopy,
    contentfulCarmaError,
    globalSettings,
  };
}

/**
 * Format the global data received from the static page generation
 *
 * This function is intended to be used within a page component
 */
export function formatPageDefaults(footerData: GetFooterQuery) {
  return {
    footerProps: formatFooterProps(footerData),
  };
}

// TODO: Copied from usePrivacyContent. Add typings in Footer component and map data here
export function formatFooterProps(footerData: GetFooterQuery) {
  return aboutCarmaHelper.getFooter(footerData.sharedFooterComponent);
}
