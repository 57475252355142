'use client';

import { FC } from 'react';
import { StatusPillContent } from './StatusPillContent';
import { useStatusPillState } from './useStatusPillState';
import { CAR_PURCHASE_STATUS } from 'src/constants';

interface Props {
  sku: string;
  state: CAR_PURCHASE_STATUS;
}

export const StatusPill: FC<Props> = ({ sku, state }) => {
  const { status } = useStatusPillState({ sku, state });

  return <StatusPillContent status={status} />;
};
