/* 
    Current formula can be found at: https://www.moneygeek.com/personal-loans/calculate-loan-payments/
*/

const CALCULATOR_MIN_PAYMENT = 0;
const CALCULATOR_MAX_PAYMENT = 5000;
export const CALCULATOR_MIN_DEPOSIT = 0;
export const CALCULATOR_MAX_DEPOSIT = 250000;
export const CALCULATOR_MIN_PURCHASE = 0;
export const CALCULATOR_MAX_PURCHASE = 250000;
const CALCULATOR_MIN_INTEREST = 0;
const CALCULATOR_MAX_INTEREST = 50;

/** Constrain a value to a range */
const rangeValue = (value: number, minValue: number, maxValue: number): number =>
  Math.max(minValue, Math.min(maxValue, value));

export function calculatePrincipal(weeklyPayment: number, interestRate: number, loanTerm: number, deposit: number) {
  const monthlyInterestRate = rangeValue(interestRate, CALCULATOR_MIN_INTEREST, CALCULATOR_MAX_INTEREST) / 100.0 / 12.0;
  const loanTermInMonths = loanTerm * 12.0;
  const monthlyPayment = (rangeValue(weeklyPayment, CALCULATOR_MIN_PAYMENT, CALCULATOR_MAX_PAYMENT) * 52.0) / 12.0;
  const denominator =
    ((1 + monthlyInterestRate) ** loanTermInMonths - 1) /
    (monthlyInterestRate * (1 + monthlyInterestRate) ** loanTermInMonths);
  const ceiledNumber =
    monthlyPayment * denominator + rangeValue(deposit, CALCULATOR_MIN_DEPOSIT, CALCULATOR_MAX_DEPOSIT);
  return Math.ceil(ceiledNumber / 100) * 100;
}

export function calculateWeeklyPayment(principal: number, interestRate: number, loanTerm: number, deposit: number) {
  const actualPrincipal =
    rangeValue(principal, CALCULATOR_MIN_PURCHASE, CALCULATOR_MAX_PURCHASE) -
    rangeValue(deposit, CALCULATOR_MIN_DEPOSIT, CALCULATOR_MAX_DEPOSIT);
  const weeklyInterestRate = rangeValue(interestRate, CALCULATOR_MIN_INTEREST, CALCULATOR_MAX_INTEREST) / 100.0 / 52.0;
  const loanTermInWeeks = loanTerm * 52.0;
  return Math.ceil((actualPrincipal * weeklyInterestRate) / (1 - 1 / (1 + weeklyInterestRate) ** loanTermInWeeks));
}
