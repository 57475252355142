import classNames from 'classnames';
import { Components } from 'react-markdown';

import { INSTAGRAM_REGEX } from 'src/constants';
import { MediaSection } from 'src/hygiene/sections/MediaSection/MediaSection';

import styles from './Markdown.module.scss';

const REEL_IDENTIFIER = 'reel';
const POST_IDENTIFITER = 'p';

export const MarkdownInstagram: Components['video'] = ({ src, node }) => {
  if (!src || typeof src !== 'string') return null;
  const instagramRegexResult = INSTAGRAM_REGEX.exec(src);
  if (!instagramRegexResult) return null;
  const postType = instagramRegexResult[1];
  const postId = instagramRegexResult[2];
  const url = `https://www.instagram.com/${postType}/${postId}/embed/`;
  const data = {
    sectionData: {
      title: node?.properties?.title?.toString(),
      subtitle: node?.properties?.subtitle?.toString(),
    },
  };

  return (
    <MediaSection
      title={data.sectionData.title}
      subTitle={data.sectionData.subtitle}
      medias={[
        {
          id: url,
          media: (
            <div className={styles.instagramContainer}>
              <iframe
                title={url}
                src={url}
                className={classNames(styles.instagram, {
                  [styles.reelHeight]: postType === REEL_IDENTIFIER,
                  [styles.postHeight]: postType === POST_IDENTIFITER,
                })}
              ></iframe>
            </div>
          ),
        },
      ]}
    />
  );
};
