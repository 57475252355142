import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { ComponentProps, FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './BrowseCars.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    direction: 'regular' | 'reverse';
    title: string;
    content?: string;
    cta: ComponentProps<typeof CTAButton>[];
    image: string;
  };
}

export const BrowseCars: FC<Props> = ({
  sectionData: { direction, title, content, cta, image },
  sectionBackground,
}) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    <div className={classNames(styles.container, { [styles.reverse]: direction === 'reverse' })}>
      <div className={styles.contentWrapper}>
        <header className={styles.header}>
          <h2 className={classNames('c-fw-bold', 'c-fs-h4', styles.title)}>{title}</h2>
        </header>
        {!isEmpty(content) && (
          <ReactMarkdown className={classNames('c-fw-normal', 'c-fs-body1', styles.content)}>{content!}</ReactMarkdown>
        )}
        <footer className={styles.footer}>
          {cta.map((item) => (
            <CTAButton key={item.label?.toString()} {...item} />
          ))}
        </footer>
      </div>
      <div className={styles.imageWrapper}>
        <Image src={image} width={648} height={216} alt="" />
      </div>
    </div>
  </section>
);
