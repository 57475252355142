import { isNil } from 'lodash';
import { Fetcher } from 'swr';

import { FormikOption } from 'src/general/components/forms/FormikInput/FormikInput.types';
import { FilterOptionHierarchy, FilterOptions } from 'src/plp/components/PlpFilters/PlpFilters.type';
import { carmaBizClient } from 'src/services/instance/carmaBizClient';
import { BodySize, BodyType, CarFilterDataRow, FilterListPayload, Model } from 'src/types/CarFilters.types';

// FilterListPayload
export const plpFilterOptionsFetcher: Fetcher<
  {
    filterData: FilterListPayload;
    filterOptions: FilterOptions;
  },
  string
> = async (filterUrl) => {
  const filterData = await carmaBizClient.get<FilterListPayload>(filterUrl).then((res) => res.data);
  return {
    filterData,
    filterOptions: filtersListPayloadToFilterOptions(filterData),
  };
};

export const filtersListPayloadToFilterOptions = (filterData: FilterListPayload): FilterOptions => ({
  makeModel: parseMakeModelFilter(filterData.makeAndModel),
  priceCash: parseSelectFilter(filterData.price.cashList, true),
  priceFinance: parseSelectFilter(filterData.price.financeList, true),
  year: parseSelectFilter(filterData.year, true),
  kilometers: parseSelectFilter(filterData.kilometers, true),
  transmission: parseCheckboxFilter(filterData.transmission),
  highlightedFeatures: parseCheckboxFilter(filterData.highlightedFeatures),
  fuelType: parseCheckboxFilter(filterData.fuelType),
  bodyType: parseCheckboxFilterBodyType(filterData.bodyType),
  driveType: parseCheckboxFilter(filterData.driveType),
  seats: parseCheckboxFilter(filterData.seats, alphabeticalSortOptions),
  doors: parseCheckboxFilter(filterData.doors, alphabeticalSortOptions),
  colour: parseCheckboxFilter(filterData.colour, alphabeticalSortOptions),
  co2Emissions: parseSelectFilter(filterData.co2Emissions),
  ancapSafetyRating: parseSelectFilter(filterData.ancapSafetyRating),
});

const parseCheckboxFilter = (
  data: CarFilterDataRow[],
  sortFunction?: (a: FormikOption, b: FormikOption) => number,
): FormikOption[] => {
  const result = data.map((item) => ({ label: formatLabelWithCount(item), value: `${item.key}` }));
  return sortFunction ? result.sort(sortFunction) : result;
};

const parseCheckboxFilterBodyType = (data: BodyType[]): FilterOptionHierarchy[] =>
  [...data]
    // Sort by quantity for make
    .sort((a, b) => (a.quantity > b.quantity ? -1 : 1))
    .map((bodyType) => ({
      label: formatLabelWithCount(bodyType),
      labelNoCount: bodyType.label,
      value: bodyType.key ?? '',
      children: (bodyType.sizes ?? ([] as BodySize[]))
        // Alphabetical sort for body sizes, at the exception of the "All" item, always at the top
        .sort((a, b) => (a.label > b.label || b.key === 'all' ? 1 : -1))
        .map((bodySize) => ({
          label: formatLabelWithCount(bodySize),
          labelNoCount: bodySize.label,
          value: (bodySize.key === 'all' ? bodyType.key : `${bodyType.key}>${bodySize.label}`) ?? '',
        })),
    }));

const parseSelectFilter = (data: CarFilterDataRow[], excludeAllOption: boolean = false): FormikOption[] =>
  data
    .map((item) => ({
      label: formatLabelWithCount(item),
      value: item.key === 'all' ? '' : `${item.key}`,
    }))
    .filter((item) => !(excludeAllOption && item.value === ''));

const parseMakeModelFilter = (data: Model[]): FilterOptionHierarchy[] =>
  [...data]
    // Sort by quantity for make
    .sort((a, b) => (a.quantity > b.quantity ? -1 : 1))
    .map((make) => ({
      label: formatLabelWithCount(make),
      labelNoCount: make.label,
      value: make.name ?? '',
      children: (make.models ?? ([] as Model[]))
        // Alphabetical sort for models, at the exception of the "All" item, always at the top
        .sort((a, b) => (a.label > b.label || b.key === 'all' ? 1 : -1))
        .map((model) => ({
          label: formatLabelWithCount(model),
          labelNoCount: model.label,
          value: (model.key === 'all' ? make.name : `${make.name}>${model.name}`) ?? '',
        })),
    }));

const alphabeticalSortOptions = (a: FormikOption, b: FormikOption) => (a.value > b.value ? 1 : -1);

const formatLabelWithCount = (item: CarFilterDataRow) =>
  isNil(item.quantity) ? item.label : `${item.label} (${item.quantity})`;
