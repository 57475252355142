import { z } from 'zod';

import { AUSTRALIAN_STATES } from 'src/constants';

const regoErrorMessage = 'Please enter a valid registration plate';
const stateErrorMessage = 'Please select a state';
const vinErrorMessage = 'Please enter a valid VIN';

export const tradeInSearchCarRegoSchema = z.object({
  rego: z.string().refine((value) => !!value && value.replaceAll(' ', '').length <= 7, { message: regoErrorMessage }),
  state: z.enum(AUSTRALIAN_STATES, {
    errorMap: () => ({ message: stateErrorMessage }),
  }),
});

export const tradeInSearchCarVINSchema = z.object({
  vin: z
    .string({ errorMap: () => ({ message: vinErrorMessage }) })
    .min(1)
    .max(20),
});

export type TradeInSearchCarRegoSchemaFields = z.infer<typeof tradeInSearchCarRegoSchema>;
export type TradeInSearchCarVINSchemaFields = z.infer<typeof tradeInSearchCarVINSchema>;
