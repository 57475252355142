import { ComponentProps, FC } from 'react';

import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { getMicrocopy, getMicrocopyBulk } from 'src/stores/microcopyStore';

import { FinanceHeroBanner } from './FinanceHeroBanner';

interface Props extends Omit<ComponentProps<typeof FinanceHeroBanner>, 'microcopy'> {}

type MicrocopyProps = ComponentProps<typeof FinanceHeroBanner>['microcopy'];

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const FinanceHeroBannerLegacy: FC<Props> = (props) => {
  const whatCanIAfford = getMicrocopyBulk({
    hygieneFinanceCalculatorTitle: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_TITLE],
    hygieneFinanceCalculatorInputLabel1: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1],
    hygieneFinanceCalculatorInputLabel2: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2],
    hygieneFinanceCalculatorLoanTitle: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE],
    hygieneFinanceCalculatorLoanTitleEstimatedBorrowAmount: [
      MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT,
    ],
    personalizedQuoteFinanceCalculatorCta: [MICROCOPY.PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA],
  }) as MicrocopyProps['whatCanIAfford'];

  const repayment = getMicrocopyBulk({
    hygieneFinanceCalculatorTitle: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_TITLE],
    hygieneFinanceCalculatorInputLabel2: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2],
    hygieneFinanceCalculatorInputLabel3: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3],
    hygieneFinanceCalculatorLoanTitle: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE],
    hygieneFinanceCalculatorLoanTitleEstimatedRepayment: [
      MICROCOPY.HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT,
    ],
    hygieneFinanceCalculatorWeeklyFee: [MICROCOPY.HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE],
    personalizedQuoteFinanceCalculatorCta: [MICROCOPY.PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA],
  }) as MicrocopyProps['repayment'];

  const enquiryForm = getMicrocopyBulk({
    formHeader: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_HEADER],
    detailSectionTitle: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE],
    messageLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL],
    firstNameLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL],
    lastNameLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL],
    emailAddressLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL],
    phoneNumberLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL],
    submitButtonLabel: [MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL],
    privacyCollectionNotice: [MICROCOPY.GENERAL_PRIVACY_COLLECTION_NOTICE, true],
  }) as MicrocopyProps['enquiryForm'];

  const errorMessage = getMicrocopy(MICROCOPY.PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE) as string;

  const microcopy: MicrocopyProps = {
    errorMessage,
    whatCanIAfford,
    repayment,
    enquiryForm,
  };

  return <FinanceHeroBanner {...props} microcopy={microcopy} />;
};
