import classNames from 'classnames';
import { ComponentProps, FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';
import { PromoPill } from 'src/general/components/PromoPill/PromoPill';

import { isEmpty } from 'lodash';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './Marketing.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    marketingTiles: {
      tagLabel?: string;
      tagIcon?: string;
      title: string;
      subTitle?: string;
      content: string;
      cta: ComponentProps<typeof CTAButton>;
      hideTagLabel?: boolean;
      desktopImageUrl: string;
      mobileImageUrl: string;
    }[];
  };
}

export const Marketing: FC<Props> = ({ sectionData: { marketingTiles }, sectionBackground }) => (
  <section
    className={classNames(
      styles.root,
      { [styles.root1Tile]: marketingTiles.length === 1 },
      getSectionClasses(sectionBackground),
    )}
  >
    {marketingTiles.map(
      ({ tagLabel, tagIcon, title, content, cta, subTitle, hideTagLabel, desktopImageUrl, mobileImageUrl }) => (
        <div className={styles.tileWrapper} key={title}>
          <article className={styles.tile}>
            <div className={styles.tileImageRatioHolder} />
            <Image
              className={classNames(styles.tileImage, styles.tileImageMobile)}
              src={mobileImageUrl}
              width={470}
              height={552}
              alt={title}
            />
            <Image
              className={classNames(styles.tileImage, styles.tileImageDesktop)}
              src={desktopImageUrl}
              width={704}
              height={336}
              alt={title}
            />
            <div className={styles.tileContent}>
              {!hideTagLabel && tagLabel && (
                <div className={classNames(styles.tagLabelContainer, styles.tileContentItem)}>
                  <PromoPill
                    promoPillData={[
                      {
                        key: tagLabel,
                        copy: tagLabel,
                        backgroundColor: '#EBF2F5',
                        color: 'rgba(21, 13, 44, 0.88)',
                        icon: tagIcon,
                      },
                    ]}
                    className={classNames('c-fw-ultrabold', 'c-fs-overline', styles.tagLabel)}
                  />
                </div>
              )}
              <header className={classNames(styles.tileHeader, styles.tileContentItem)}>
                <h2 className={classNames('c-fw-bold', 'c-fs-h5', styles.tileTitle)}>{title}</h2>
                {!isEmpty(subTitle) && (
                  <p className={classNames('c-fw-normal', 'c-fs-h6', styles.tileSubtitle)}>{subTitle}</p>
                )}
              </header>
              <ReactMarkdown
                className={classNames('c-fw-normal', 'c-fs-body1', styles.tileDescription, styles.tileContentItem)}
              >
                {content}
              </ReactMarkdown>
              <footer className={styles.tileFooter}>
                <CTAButton {...cta} />
              </footer>
            </div>
          </article>
        </div>
      ),
    )}
  </section>
);
