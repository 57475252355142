import { gql } from '@apollo/client';

import { contentfulQueryFetch } from './helpers/contentfulQuery';

/**
 * Interface associated with Global Settings
 */
export type GetGlobalSettings = {
  globalSettings: {
    globalJsonLd: Object[] | Object;
  };
};

/**
 * Query to retrieve a Contentful Global Settings
 */
const GET_GLOBAL_SETTINGS = gql`
  query GetGlobalSettings($id: String!, $preview: Boolean!) {
    globalSettings(id: $id, preview: $preview) {
      globalJsonLd
    }
  }
`;

/**
 * Fetch the data
 */
export async function getGlobalSettings(id: string, preview?: boolean) {
  return contentfulQueryFetch<GetGlobalSettings>(
    GET_GLOBAL_SETTINGS,
    {
      revalidate: 3600,
      tags: ['global-settings', `global-settings/${id}`],
    },
    {
      id,
      preview,
    },
    preview,
  );
}
