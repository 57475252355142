import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { MarkdownLegacy } from 'src/general/components/Markdown/MarkdownLegacy';
import { AccordionSection as AccordionSectionBase } from 'src/general/sections/AccordionSection/AccordionSection';

import { FlexibleSection } from '../flexibleContent.type';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    items: Array<{
      title: string;
      content: string;
    }>;
    cta: ComponentProps<typeof CTAButton>;
  };
}

export const AccordionSection: FC<Props> = ({ sectionData: { title, items, cta }, sectionBackground }) => (
  <AccordionSectionBase
    title={title}
    items={items.map(({ title, content }) => ({
      id: title,
      header: title,
      content: <MarkdownLegacy>{content}</MarkdownLegacy>,
    }))}
    cta={cta}
    sectionBackground={sectionBackground}
  />
);
