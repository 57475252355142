import classNames from 'classnames';
import { FC } from 'react';
import { Image } from 'src/general/components/Image/Image';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './CarmaBusinessPartners.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    logos: Array<{ url: string; title: string }>;
  };
  className?: string;
}

export const CarmaBusinessPartners: FC<Props> = ({ sectionData: { title, logos }, className, sectionBackground }) => {
  return (
    <section className={classNames(styles.root, className, getSectionClasses(sectionBackground))}>
      <header className={styles.header}>
        <h4 className={classNames('c-fw-bold', 'c-fs-body1', styles.title)}>{title}</h4>
      </header>
      <div className={styles.logosWrapper}>
        {logos.map((logo) => (
          <div className={styles.logo} key={logo.title}>
            <Image src={logo.url} alt={logo.title} className={styles.logoImage} width={240} height={240} />
          </div>
        ))}
      </div>
    </section>
  );
};
