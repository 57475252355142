import { gql } from '@apollo/client';

import contentfulQuery from './helpers/contentfulQuery';

export interface ContentfulCarmaError {
  errorKey: string;
  errorCode: string;
  errorMessage: string;
}

/**
 * Interface associated with listCarmaErrorQuery
 */
export interface ListCarmaErrorQuery {
  carmaErrorsCollection: {
    __typename: 'CarmaErrorsCollection';
    items: ContentfulCarmaError[];
  };
}

/**
 * Query to retrieve a collection of CarmaError
 */
const listCarmaErrorQuery = gql`
  query GetPage($limit: Int!, $skip: Int!, $preview: Boolean!) {
    carmaErrorsCollection(limit: $limit, skip: $skip, preview: $preview) {
      items {
        errorKey
        errorCode
        errorMessage
      }
    }
  }
`;

/**
 * Fetch a collection of Microcopy
 */
function listCarmaErrorData(limit: number, skip: number, preview: boolean) {
  return contentfulQuery<ListCarmaErrorQuery>(
    listCarmaErrorQuery,
    {
      limit,
      skip,
      preview,
    },
    preview,
  );
}

/**
 * Fetch all the Microcopy
 */
export async function listAllCarmaErrorPages(preview: boolean) {
  const listAll: ContentfulCarmaError[] = [];
  const pagintionLimit = 100;
  let totalReturnedItems = 0;
  let currentPage = 0;
  // Build the list of all the error
  do {
    // await in a loop is required in this case
    // eslint-disable-next-line no-await-in-loop
    const result = await listCarmaErrorData(pagintionLimit, currentPage * pagintionLimit, preview);

    listAll.push(...result.carmaErrorsCollection.items);
    totalReturnedItems = result.carmaErrorsCollection.items.length;
    currentPage += 1;
  } while (totalReturnedItems === pagintionLimit);
  return listAll;
}
