import classNames from 'classnames';
import { Components } from 'react-markdown';

import { InlineImage } from 'src/hygiene/sections/flexible-content/InlineImage/InlineImage';
import { getOr } from 'src/utils/getOr';

import { Carousel } from '../Carousel/Carousel';
import styles from './MarkdownCarousel.module.scss';

export const MarkdownCarousel: Components['div'] = ({ node }) => {
  const filteredChildren = node.children.filter((item) => item.type === 'element' && item.tagName === 'img');

  if (filteredChildren.length === 0) {
    return null;
  }

  return (
    <section className={classNames(styles.root, 'markdownCarousel')}>
      <Carousel
        className={styles.carousel}
        tiles={filteredChildren.map((item, index) => {
          const url = getOr(item, 'properties.src', '');
          const description = getOr(item, 'properties.description', undefined);
          return {
            id: `${index}-${getOr(item, 'properties.src', '')}`,
            tile: (
              <InlineImage
                sectionData={{
                  images: [
                    {
                      url,
                      description,
                    },
                  ],
                }}
                isInCarousel
              />
            ),
          };
        })}
      />
    </section>
  );
};
