import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconStar: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15 1.366a1 1 0 0 1 1.7 0l1.95 3.138a1 1 0 0 0 .61.443l3.588.886a1 1 0 0 1 .525 1.616l-2.382 2.825a1 1 0 0 0-.233.717l.266 3.686a1 1 0 0 1-1.374.998l-3.423-1.392a1 1 0 0 0-.754 0L4.2 15.675a1 1 0 0 1-1.374-.998l.266-3.686a1 1 0 0 0-.233-.717L.477 7.45a1 1 0 0 1 .525-1.616l3.588-.886a1 1 0 0 0 .61-.443l1.95-3.138Z"
    />
  </Icon>
);
