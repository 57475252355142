import { ComponentProps } from 'react';
import { Components } from 'react-markdown';

import { InlineVideo } from 'src/hygiene/sections/flexible-content/InlineVideo/InlineVideo';

export const MarkdownVideo: Components['video'] = ({ src, node }) => {
  const data: ComponentProps<typeof InlineVideo> = {
    sectionData: {
      title: node.properties?.title?.toString() ?? '',
      subTitle: node.properties?.subtitle?.toString() ?? '',
      youtubeVideo: {
        videoUrl: src!,
        thumbnailImageUrl: node.properties?.thumbnail?.toString() ?? '',
      },
      videoDescription: node.properties?.description?.toString() ?? '',
    },
  };

  return <InlineVideo sectionData={data.sectionData} setButtonCentered={true} />;
};
