export const sortBySku = <T extends { sku: string }[]>(results: T, skus: string[]): T => {
  return (results.slice() as T).sort((a, b) => {
    const skuAIndex = skus.indexOf(a.sku);
    const skuBIndex = skus.indexOf(b.sku);

    if (skuAIndex === -1) {
      return 1; // Place items without sku at the end
    } else if (skuBIndex === -1) {
      return -1; // Place items with sku before those without
    }

    return skuAIndex - skuBIndex;
  });
};
