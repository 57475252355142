import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconEmail: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.333 9A4.333 4.333 0 0 0 4 13.333v13.334A4.333 4.333 0 0 0 8.333 31h23.333A4.333 4.333 0 0 0 36 26.667V13.333A4.333 4.333 0 0 0 31.666 9H8.333Zm-.732 2.117c.23-.076.476-.117.732-.117h23.333c.256 0 .503.041.733.117L21.4 19.367c-.83.622-1.97.622-2.8 0l-11-8.25Zm-1.457 1.407a2.328 2.328 0 0 0-.144.81v13.333A2.333 2.333 0 0 0 8.333 29h23.333A2.333 2.333 0 0 0 34 26.667V13.333c0-.284-.051-.556-.144-.808L22.6 20.967a4.334 4.334 0 0 1-5.2 0L6.144 12.524Z"
    />
  </Icon>
);
