import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCaretLeft: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.998 5.151-6.424 6.425a.6.6 0 0 0 0 .848l6.424 6.424.849-.848-6-6 6-6-.849-.849Z"
    />
  </Icon>
);
