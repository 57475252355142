import { ComponentProps, FC } from 'react';

import { YoutubeVideo } from 'src/general/components/YoutubeVideo/YoutubeVideo';

import { MediaSection } from '../../MediaSection/MediaSection';
import { FlexibleSection } from '../flexibleContent.type';
import { InlineVideoJsonLD } from './InlineVideoJsonLD';

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    subTitle?: string;
    youtubeVideo: ComponentProps<typeof YoutubeVideo>;
    videoDescription?: string;
  };
  className?: string;
  setButtonCentered?: boolean;
  isInCarousel?: boolean;
}

export const InlineVideo: FC<Props> = ({
  sys,
  sectionData: { youtubeVideo, subTitle, title, videoDescription },
  sectionBackground,
  setButtonCentered,
  className,
  isInCarousel,
}) => (
  <>
    <InlineVideoJsonLD sectionData={{ youtubeVideo, subTitle, title, videoDescription }} sys={sys} />
    <MediaSection
      title={title}
      subTitle={subTitle}
      className={className}
      sectionBackground={sectionBackground}
      medias={[
        {
          id: 'youtubeVideo.videoUrl',
          media: (
            <YoutubeVideo
              width={848}
              height={477}
              thumbnailImageUrl={youtubeVideo.thumbnailImageUrl}
              videoUrl={youtubeVideo.videoUrl}
              setButtonCentered={setButtonCentered}
            />
          ),
          description: videoDescription,
        },
      ]}
      isInCarousel={isInCarousel}
    />
  </>
);
