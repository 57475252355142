import { ComponentProps, FC } from 'react';

import { ReviewSummaryLegacy } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummaryLegacy';

import { CustomerReviewsScore } from './CustomerReviewsScore';

type Props = Omit<ComponentProps<typeof CustomerReviewsScore>, 'ReviewSummaryComponent'>;

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const CustomerReviewsScoreLegacy: FC<Props> = (props) => (
  <CustomerReviewsScore {...props} ReviewSummaryComponent={ReviewSummaryLegacy} />
);
