import { FC, ReactNode } from 'react';

import { ArticlesGrid } from 'src/content-hub/components/ArticlesGrid/ArticlesGrid';
import { PageArticleDataNoContent } from 'src/data/Contentful/ContentfulGetArticle';

import classNames from 'classnames';
import { SECTION_BACKGROUND } from 'src/hygiene/sections/flexible-content/flexibleContent.type';
import { getSectionClasses } from 'src/hygiene/sections/flexible-content/getSectionClasses.helper';
import styles from './RelatedArticles.module.scss';

interface Props {
  title: ReactNode;
  articles: PageArticleDataNoContent[];
  sectionBackground?: SECTION_BACKGROUND;
}

export const RelatedArticles: FC<Props> = ({ title, articles, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground, 'contentHub'))}>
    <header className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
    </header>
    <ArticlesGrid className={styles.articles} items={articles} />
  </section>
);
