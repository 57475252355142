'use client';

import { Tab, Tabs } from '@material-ui/core';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { ComponentType, FC, ReactNode, useMemo, useState } from 'react';

import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import classes from './CarouselSectionLegacy.module.scss';
import { CarouselTab } from './CarouselSectionLegacy.type';

interface CarouselSectionProps<T extends ComponentType<any>> {
  title?: ReactNode;
  CarouselComponent: T;
  tabs: CarouselTab<T>[];
  /** Name used to track GTM events */
  carouselName?: string;
  className?: string;
}

/**
 * Hygiene page section containing a carousel.
 *
 * Consider refactoring out tab functionality to its own component. i.e:
 * - TabbedCarouselSection: Similar to this component currently, assumes tabs are given and visible
 * - CarouselSection: The functionality of this component with only 'one' tab given.
 */
export const CarouselSectionLegacy = <T extends ComponentType<any>>({
  title,
  CarouselComponent,
  tabs,
  carouselName,
  className,
}: CarouselSectionProps<T>): ReturnType<FC<CarouselSectionProps<T>>> => {
  const hasMultipleTabs = tabs.length > 1;
  const [activeTab, setActiveTab] = useState<string>(tabs.length ? tabs[0].id : '');

  const tabElements = useMemo(
    () =>
      tabs.map(({ id, tabLabel }) => (
        <Tab
          key={id}
          label={<>{tabLabel}</>}
          value={id}
          id={`tabbed-carousel-tab-${id}`}
          aria-controls={`tabbed-carousel-content-${id}`}
        />
      )),
    [tabs],
  );

  const carouselContent = useMemo(
    () =>
      tabs.reduce<Record<CarouselTab<T>['id'], CarouselTab<T>['content']>>(
        (acc, { id, content }) => ({ ...acc, [id]: { ...content, carouselName, carouselValue: id } }),
        {},
      ),
    [tabs],
  );

  return (
    <section className={classNames(classes.root, className)}>
      <header className={classes.container}>
        {(!isNil(title) || !isEmpty(title)) && (
          <h2 className={classNames('c-fw-bold', 'c-fs-h4', classes.title)}>{title}</h2>
        )}
        {hasMultipleTabs && (
          <nav className={classes.tabsWrapper}>
            <Tabs
              value={activeTab}
              onChange={(_e, value) => {
                setActiveTab(value);
                if (!isEmpty(carouselName)) {
                  pushToDataLayer({
                    event: 'carousel_tab_change',
                    carousel: carouselName,
                    tab: value,
                  });
                }
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              {tabElements}
            </Tabs>
          </nav>
        )}
      </header>
      <div
        role="tabpanel"
        id={`tabbed-carousel-content-${activeTab}`}
        aria-labelledby={`tabbed-carousel-tab-${activeTab}`}
      >
        <CarouselComponent {...carouselContent[activeTab]} />
      </div>
    </section>
  );
};
