import { array, object, string } from 'yup';

import { SortValues, sortValuesList } from 'src/types/CarFilters.types';

import { ContentfulRichText } from './ContentfulTypes';

export const pageProductListingOptionsValidation = object({
  sortBy: string()
    .oneOf(Object.keys(sortValuesList) as SortValues[])
    .optional(),
  page: string().optional(),
});

/**
 * Listing option for the PLP
 */
export type PageProductListingOptions = Partial<ReturnType<typeof pageProductListingOptionsValidation.validateSync>>;

export const pageProductListingFiltersValidation = object({
  /** Expects an array of strings. Each string should be formatted as follow: "make" or "make>model" */
  makeModel: array(string().matches(/([a-zA-Z0-9>-]+)/)).optional(),
  /** Keyword search */
  search: string().optional(),
  // Price cash
  priceCashMin: string().optional(),
  priceCashMax: string().optional(),
  // Price finance
  priceFinMin: string().optional(),
  priceFinMax: string().optional(),
  // Year
  yearMin: string().optional(),
  yearMax: string().optional(),
  // Kilometers
  kmMin: string().optional(),
  kmMax: string().optional(),
  // Engine power
  enginePowerMin: string().optional(),
  enginePowerMax: string().optional(),
  // Fuel consumption
  fuelConsumptionMin: string().optional(),
  fuelConsumptionMax: string().optional(),
  transmission: array(string()).optional(),
  fuelType: array(string()).optional(),
  bodyType: array(string()).optional(),
  driveType: array(string()).optional(),
  highlightedFeatures: array(string()).optional(),
  seats: array(string()).optional(),
  doors: array(string()).optional(),
  colour: array(string()).optional(),
  co2Emissions: string().optional(),
  ancapSafetyRating: string().optional(),
  state: string().optional(),
  promotionTags: string().optional(),
});

/**
 * Filter options for the PLP
 */
export type PageProductListingFilters = Partial<ReturnType<typeof pageProductListingFiltersValidation.validateSync>>;

export interface PageProductListing {
  slug: string;
  seoPageTitle?: string;
  seoPageDescription?: string;
  noindex?: boolean;
  socialMetaImage?: {
    url: string;
  };
  listingTitle: string;
  headerCopy?: ContentfulRichText;
  footerCopy?: ContentfulRichText;
  pageFilters: PageProductListingFilters;
}

/**
 * Interface associated with getPageProductListingBySlugQuery
 */
export interface GetPageProductListingBySlugQuery {
  pageProductListingCollection: {
    __typename: 'PageProductListingCollection';
    items: PageProductListing[];
  };
}
