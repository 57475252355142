import { ComponentProps, FC } from 'react';

import { ReviewSummaryLegacy } from 'src/hygiene/components/flexible-content/ReviewSummary/ReviewSummaryLegacy';

import { CustomerReviews } from './CustomerReviews';

type Props = Omit<ComponentProps<typeof CustomerReviews>, 'ReviewSummaryComponent'>;

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const CustomerReviewsLegacy: FC<Props> = (props) => (
  <CustomerReviews {...props} ReviewSummaryComponent={ReviewSummaryLegacy} />
);
