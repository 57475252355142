import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { IconStar } from 'src/general/Icons/IconStar';

import styles from './Ratings.module.scss';

type Props = {
  rating: number;
  className?: string;
};

const MAX_STARS = 5;

export const Ratings: FC<Props> = ({ rating, className }) => {
  const starValues = useMemo(() => {
    const results: { index: number; value: number }[] = [];
    for (let index = 0; index < MAX_STARS; index++) {
      results.push({
        index,
        value: Math.min(1, Math.max(0, rating - index)),
      });
    }
    return results;
  }, [rating]);

  return (
    <div className={classNames(styles.stars, className)} title={`${rating} Stars`}>
      {starValues.map((item) => (
        <RatingStar key={item.index} value={item.value} />
      ))}
    </div>
  );
};

type StarProps = {
  /** Number between 0 and 1 */
  value: number;
};

const RatingStar: FC<StarProps> = ({ value }) => {
  if (value === 1) {
    return <IconStar className={styles.starFull} />;
  }
  if (value === 0) {
    return <IconStar className={styles.starEmpty} />;
  }

  return (
    <div className={styles.starWrapper} aria-hidden="true">
      <IconStar className={styles.starEmpty} />
      <div className={styles.starWrapperFull} style={{ width: `${Math.round(value * 100)}%` }}>
        <IconStar className={styles.starFull} />
      </div>
    </div>
  );
};
