import classNames from 'classnames';
import { AnimatePresence, LazyMotion, domAnimation, m } from 'framer-motion';
import { FC } from 'react';

import styles from './StatusPill.module.scss';
import { PillPurchaseStatuses } from './StatusPill.type';

interface Props {
  status?: PillPurchaseStatuses;
}

const copy: { [key in PillPurchaseStatuses]: string } = {
  myPurchase: 'My Purchase',
  draftOrder: 'On hold',
  reserved: 'Deposit taken',
};

export const StatusPillContent: FC<Props> = ({ status }) => {
  const content = status ? copy[status] : null;
  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence>
        {!!status && (
          <m.div
            key={content}
            className={classNames(styles.root, status, 'c-fs-overline')}
            data-testid="PurchaseStatusPill-component-purchaseStatusText"
            transition={{ ease: 'easeInOut', duration: 200 / 1000 }}
            initial={{
              opacity: 0,
              transform: 'translate3d(0, -0.25rem, 0)',
            }}
            animate={{
              opacity: 1,
              transform: 'translate3d(0, 0, 0)',
            }}
          >
            {content}
          </m.div>
        )}
      </AnimatePresence>
    </LazyMotion>
  );
};
