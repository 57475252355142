import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconWatchingPeople: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M9 13.217A2.133 2.133 0 1 0 9 8.95a2.133 2.133 0 0 0 0 4.267Zm0 1.2A3.333 3.333 0 1 0 9 7.75a3.333 3.333 0 0 0 0 6.667ZM3.4 20.083a4.6 4.6 0 0 1 4.6-4.6h2a4.6 4.6 0 0 1 4.6 4.6v.767h-1.2v-.767a3.4 3.4 0 0 0-3.4-3.4H8a3.4 3.4 0 0 0-3.4 3.4v.767H3.4v-.767ZM16.503 4l-.066-.062c-.46-.432-1-.938-1.937-.938-1.963 0-3.408 2.5-1.825 4.5 1.582 2 3.326 3.25 3.828 3.5.495-.25 2.24-1.5 3.822-3.5 1.583-2 .138-4.5-1.826-4.5-.95 0-1.493.52-1.95.956L16.503 4ZM16.5 9.572a15.705 15.705 0 0 0 2.883-2.817c.991-1.252.026-2.555-.885-2.555-.446 0-.673.196-1.169.669l-.82.78-.827-.772c-.516-.483-.736-.677-1.182-.677-.911 0-1.877 1.303-.885 2.555A15.634 15.634 0 0 0 16.5 9.572Z"
      clipRule="evenodd"
    />
  </Icon>
);
