import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconMessage: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.333 7.667A2.333 2.333 0 0 0 8 10v13.333a2.333 2.333 0 0 0 2.333 2.334H12a2.667 2.667 0 0 1 2.666 2.666v3.712c0 .58.69.883 1.118.491l6.729-6.168c.492-.451 1.135-.701 1.802-.701h6.018a2.333 2.333 0 0 0 2.334-2.334V10a2.333 2.333 0 0 0-2.334-2.333h-20ZM6 10a4.333 4.333 0 0 1 4.333-4.333h20A4.333 4.333 0 0 1 34.667 10v13.333a4.333 4.333 0 0 1-4.334 4.334h-6.018a.667.667 0 0 0-.45.175l-6.73 6.168c-1.71 1.568-4.469.355-4.469-1.965v-3.712a.667.667 0 0 0-.666-.666h-1.667A4.333 4.333 0 0 1 6 23.333V10Z"
    />
    <path d="M13.667 14.333H27v-2H13.667v2ZM13.667 21h6.666v-2h-6.666v2Z" />
  </Icon>
);
