import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { Accordion } from 'src/general/components/Accordion/Accordion';
import { CTAButton } from 'src/general/components/Button/CTAButton';

import { SECTION_BACKGROUND } from 'src/hygiene/sections/flexible-content/flexibleContent.type';
import { getSectionClasses } from 'src/hygiene/sections/flexible-content/getSectionClasses.helper';
import styles from './AccordionSection.module.scss';

interface AccordionSectionProps extends ComponentProps<typeof Accordion> {
  title: string;
  cta?: ComponentProps<typeof CTAButton>;
  sectionBackground?: SECTION_BACKGROUND;
}

export const AccordionSection: FC<AccordionSectionProps> = ({
  title,
  items,
  trackingName,
  cta,
  sectionBackground = 'white',
}) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
    {title && (
      <header className={styles.header}>
        <h2 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h2>
      </header>
    )}
    <Accordion className={styles.accordion} items={items} trackingName={trackingName} />
    {cta && (
      <footer className={styles.footer}>
        <CTAButton {...cta} />
      </footer>
    )}
  </section>
);
