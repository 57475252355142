import dayjs from 'dayjs';

import { PageArticleDataNoContent } from 'src/data/Contentful/ContentfulGetArticle';

export const getArticlePublishDate = (
  article: PageArticleDataNoContent,
  format: string = 'MMM DD, YYYY',
): string | undefined => {
  if (article.publicationDate) {
    return dayjs(article.publicationDate).format(format);
  }
  return undefined;
};
