import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCircleExclamation: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M7.50033 4.66667C7.50033 4.94281 7.72418 5.16667 8.00033 5.16667C8.27647 5.16667 8.50033 4.94281 8.50033 4.66667C8.50033 4.39052 8.27647 4.16667 8.00033 4.16667C7.72418 4.16667 7.50033 4.39052 7.50033 4.66667Z"
      fill="#150D2C"
      fillOpacity="0.878431"
    />
    <path d="M7.50008 11.8333V7.25H6.66675V6.25H8.50008V11.8333H7.50008Z" fill="#150D2C" fillOpacity="0.878431" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8Z"
      fill="#150D2C"
      fillOpacity="0.878431"
    />
  </Icon>
);
