import { gql } from '@apollo/client';

import { ContentfulNavigation, ContentfulRichText } from './ContentfulTypes';
import { contentfulQueryFetch } from './helpers/contentfulQuery';

/**
 * Interface associated with getFooterQuery
 */
export interface GetFooterQuery {
  sharedFooterComponent: {
    internalName: string;
    supportTitle: string;
    supportWorkingTime: string;
    supportTextButton: string;
    supportCallText: string;
    supportIconButton: {
      url: string;
    };
    supportIconCall: {
      url: string;
    };
    helpModalContent: ContentfulRichText;
    aboutUsLinkBlockHeader: string;
    aboutUsLinkBlockCollection: {
      items: ContentfulNavigation[];
    };
    supportHeader: string;
    supportLinkBlockCollection: {
      items: ContentfulNavigation[];
    };
    popularModelsLinkBlockHeader: string;
    popularModelsLinkBlockCollection: {
      items: ContentfulNavigation[];
    };
    popularCarTypesBlockLinkHeader: string;
    popularCarTypesLinkBlockCollection: {
      items: ContentfulNavigation[];
    };
    followUsLinkBlockHeader: string;
    followUsBlockLinkCollection: {
      items: ContentfulNavigation[];
    };
    description: ContentfulRichText;
    copyrightText: string;
    termsAndConditions: ContentfulNavigation;
    privaryPolicy: ContentfulNavigation;
  };
}

/**
 * Query to retrieve a Contentful footer content
 */
const getFooterQuery = gql`
  query GetFooter($id: String!, $preview: Boolean!) {
    sharedFooterComponent(id: $id, preview: $preview) {
      internalName
      supportTitle
      supportWorkingTime
      supportTextButton
      supportCallText
      supportIconButton {
        url
      }
      supportIconCall {
        url
      }
      helpModalContent {
        json
      }
      aboutUsLinkBlockHeader
      aboutUsLinkBlockCollection {
        items {
          text
          link
        }
      }
      supportHeader
      supportLinkBlockCollection {
        items {
          text
          link
        }
      }
      popularModelsLinkBlockHeader
      popularModelsLinkBlockCollection {
        items {
          text
          link
        }
      }
      popularCarTypesBlockLinkHeader
      popularCarTypesLinkBlockCollection {
        items {
          text
          link
        }
      }
      followUsLinkBlockHeader
      followUsBlockLinkCollection {
        items {
          text
          link
        }
      }
      description {
        json
      }
      copyrightText
      termsAndConditions {
        text
        link
      }
      privaryPolicy {
        text
        link
      }
    }
  }
`;

/**
 * Fetch the footer data
 * @param id the footer id to fetch
 */
export async function getFooterData(id: string, preview = false) {
  return contentfulQueryFetch<GetFooterQuery>(
    getFooterQuery,
    {
      revalidate: 3600,
      tags: ['page', 'footer'],
    },
    {
      id,
      preview,
    },
    preview,
  );
}
