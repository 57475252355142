import { isEmpty, isNil } from 'lodash';

import {
  PageProductListingFilters,
  PageProductListingOptions,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import {
  BodyType,
  FilterListPayload,
  Model,
  PlpFilterParamBooleanKeys,
  PlpFilterParamRange,
  PlpFilterParamValue,
  PlpFilterParams,
} from 'src/types/CarFilters.types';

import { parseBodyTypesAndSizes, parseCategory } from '../filterHelper';
import { getOr } from '../getOr';

const arrayToObjectKeys = (value: string[]): PlpFilterParamBooleanKeys => {
  const result: PlpFilterParamBooleanKeys = {};
  value.forEach((key) => {
    result[key] = true;
  });
  return result;
};

const getResultMakeAndModels = (
  plpStoreFiltersMakeModel: string[],
  makeAndModelFilterData: Model[],
): Model[] | undefined => {
  const makeModels: Model[] = [];
  let invalidMakeModel = true;

  if (plpStoreFiltersMakeModel.length === 0) {
    return makeModels;
  }

  plpStoreFiltersMakeModel.forEach((item) => {
    // Split the make and model
    const [makeParam, modelParam] = item.split('>');
    const category: Model[] = parseCategory(makeAndModelFilterData, makeParam, modelParam);
    if (category.length === 0) {
      return makeModels;
    }
    if (category.length === 1 && makeModels.length > 0) {
      // Check if the same category already exists and merge the models
      const makeModelIndex = makeModels.findIndex((item) => item.key === category[0].key);

      if (makeModelIndex >= 0) {
        makeModels[makeModelIndex].models = [
          ...getOr(makeModels, `[${makeModelIndex}].models`, []),
          ...getOr(category, `[0].models`, []),
        ];
      } else {
        makeModels.push(...category);
      }
    } else {
      makeModels.push(...category);
    }
    invalidMakeModel = false;
  });

  if (invalidMakeModel) {
    return undefined;
  }
  return makeModels;
};

const getResultBodyTypesAndSizes = (
  plpStoreFiltersBodyTypeAndSize: string[],
  bodyTypeAndSizeFilterData: BodyType[],
) => {
  const bodyTypesAndSizes: BodyType[] = [];

  plpStoreFiltersBodyTypeAndSize.forEach((item) => {
    // Split the body type and body size
    const [bodyTypeParam, bodySizeParam] = item.split('>');

    const category: BodyType[] = parseBodyTypesAndSizes(bodyTypeAndSizeFilterData, bodyTypeParam, bodySizeParam);
    if (category.length === 1 && bodyTypesAndSizes.length > 0) {
      // Check if the same category already exists and merge the body sizes
      const bodyTypeAndSizeIndex = bodyTypesAndSizes.findIndex((item) => item.key === category[0].key);

      if (bodyTypeAndSizeIndex >= 0) {
        bodyTypesAndSizes[bodyTypeAndSizeIndex].sizes = [
          ...getOr(bodyTypesAndSizes, `[${bodyTypeAndSizeIndex}].sizes`, []),
          ...getOr(category, `[0].sizes`, []),
        ];
      } else {
        bodyTypesAndSizes.push(...category);
      }
    } else {
      bodyTypesAndSizes.push(...category);
    }
  });

  return bodyTypesAndSizes;
};

export const plpStoreFiltersToFilterParams = (
  plpStoreFilters: PageProductListingFilters,
  plpStoreListingOptions: PageProductListingOptions,
  filterData: FilterListPayload,
): PlpFilterParams => {
  const result: PlpFilterParams = {};

  // Handle keyword
  if (!isNil(plpStoreFilters.search) && !isEmpty(plpStoreFilters.search)) {
    result.keyword = { search: plpStoreFilters.search };
  }

  // Handle state
  result.state = plpStoreFilters.state;

  // Handle promotion tags
  result.promotionTags = plpStoreFilters.promotionTags;

  // Handle make and models
  if (plpStoreFilters.makeModel) {
    result.makeAndModel = getResultMakeAndModels(plpStoreFilters.makeModel, filterData.makeAndModel);
  } else {
    result.makeAndModel = [];
  }

  if (plpStoreFilters.bodyType) {
    result.bodyType = getResultBodyTypesAndSizes(plpStoreFilters.bodyType, filterData.bodyType);
  }

  // Handle price cash
  handlePriceOptions(result, plpStoreFilters);

  // Handle year
  result.year = handleRangeProp(plpStoreFilters, 'yearMin', 'yearMax');

  // Handle kms
  result.kilometers = handleRangeProp(plpStoreFilters, 'kmMin', 'kmMax');

  // Engine power
  result.enginePower = handleRangeProp(plpStoreFilters, 'enginePowerMin', 'enginePowerMax');

  // Fuel consumption
  result.fuelConsumption = handleRangeProp(plpStoreFilters, 'fuelConsumptionMin', 'fuelConsumptionMax');

  // The list of param properties that are arrays
  const arrayParamsKeys: Array<
    KeysMatching<PlpFilterParams, PlpFilterParamBooleanKeys> & KeysMatching<PageProductListingFilters, string[]>
  > = ['transmission', 'fuelType', 'driveType', 'seats', 'doors', 'colour', 'highlightedFeatures'];

  arrayParamsKeys.forEach((key) => {
    const param = plpStoreFilters[key];
    if (!isNil(param) && !isEmpty(param)) {
      result[key] = arrayToObjectKeys(param);
    }
  });

  // The list of param properties that are strings
  const stringParamsKeys: Array<
    KeysMatching<PlpFilterParams, PlpFilterParamValue> & KeysMatching<PageProductListingFilters, string>
  > = ['co2Emissions', 'ancapSafetyRating'];

  stringParamsKeys.forEach((key) => {
    const param = plpStoreFilters[key];
    if (!isNil(param) && !isEmpty(param)) {
      result[key] = { value: param };
    }
  });

  // The page listing options
  handleListingOptions(result, plpStoreListingOptions);

  return result;
};

const handleRangeProp = (
  plpStoreFilters: PageProductListingFilters,
  minKey: KeysMatching<PageProductListingFilters, string> & `${string}Min`,
  maxKey: KeysMatching<PageProductListingFilters, string> & `${string}Max`,
): PlpFilterParamRange | undefined => {
  if (plpStoreFilters[minKey] || plpStoreFilters[maxKey]) {
    return {
      min: plpStoreFilters[minKey] ?? '',
      max: plpStoreFilters[maxKey] ?? '',
    };
  }
  return undefined;
};

const handlePriceOptions = (filterParams: PlpFilterParams, plpStoreFilters: PageProductListingFilters) => {
  // Handle price cash
  if (plpStoreFilters.priceCashMin || plpStoreFilters.priceCashMax) {
    filterParams.price = {
      priceType: 'cash',
      min: plpStoreFilters.priceCashMin ?? '',
      max: plpStoreFilters.priceCashMax ?? '',
    };
  }
  // Handle price finance
  if (plpStoreFilters.priceFinMin || plpStoreFilters.priceFinMax) {
    filterParams.price = {
      priceType: 'finance',
      min: plpStoreFilters.priceFinMin ?? '',
      max: plpStoreFilters.priceFinMax ?? '',
    };
  }
};

const handleListingOptions = (filterParams: PlpFilterParams, plpStoreListingOptions: PageProductListingOptions) => {
  if (!isNil(plpStoreListingOptions.page) && !isEmpty(plpStoreListingOptions.page)) {
    filterParams.page = parseInt(plpStoreListingOptions.page, 10);
  }

  if (!isNil(plpStoreListingOptions.sortBy) && !isEmpty(plpStoreListingOptions.sortBy)) {
    filterParams.sortBy = plpStoreListingOptions.sortBy;
  }
};
