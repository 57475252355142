'use client';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/navigation';
import { FC, FormEvent, FormEventHandler, ReactNode, useCallback, useState } from 'react';

import { AUSTRALIAN_STATES, TRADE_IN_FORM_PATH } from 'src/constants';
import { Button } from 'src/general/components/Button/Button';
import { RecaptchaNotice } from 'src/general/components/RecaptchaNotice/RecaptchaNotice';
import { Tab, Tabs } from 'src/general/components/Tabs/Tabs';
import { Input } from 'src/general/components/forms/Input/Input';
import { InputSelect } from 'src/general/components/forms/Input/InputSelect';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import classNames from 'classnames';
import { tradeInSearchCarRegoAction, tradeInSearchCarVINAction } from './TradeInSearchCar.action';
import styles from './TradeInSearchCar.module.scss';

export const TradeInSearchCar: FC = () => {
  const [errors, setErrors] = useState<Record<string, string[] | undefined>>({});
  const router = useRouter();
  const flags = useFlags();

  const onSubmit = useCallback(
    (action: (data: FormData) => { status: string; data?: any; errors?: any }) =>
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const result = action(new FormData(event.currentTarget));

        if (result.status === 'success') {
          const searchByVin = !!result.data.vin;

          pushToDataLayer({
            event: 'trade_in_step_car_lookup',
            type: searchByVin ? 'VIN' : 'Registration',
          });

          router.push(
            `${TRADE_IN_FORM_PATH}?${new URLSearchParams({
              ...result.data,
              searchType: searchByVin ? 'byVin' : 'byRego',
            })}`,
          );
        } else {
          setErrors(result.errors);
        }
      },
    [router, flags],
  );

  return (
    <Tabs className={styles.root} tabStyle={styles.tab} tabStyleActive={styles.tabActive}>
      <TradeInTab
        label="Registration plate"
        tabHeaderTestId="EXCHANGE_CAR_REGO_PLATE"
        submitTestId="ExchangeACar-component-continueButton"
        onSubmit={onSubmit(tradeInSearchCarRegoAction)}
      >
        <Input id="rego" name="rego" type="text" label="Registration plate" error={getError(errors, 'rego')} />
        <InputSelect
          id="state"
          name="state"
          label="State of registration"
          allowEmpty
          error={getError(errors, 'state')}
          options={AUSTRALIAN_STATES.map((state) => ({ label: state, value: state }))}
        />
      </TradeInTab>
      <TradeInTab
        label="VIN"
        tabHeaderTestId="EXCHANGE_CAR_VIN"
        submitTestId="ExchangeACar-component-vinContinueButton"
        onSubmit={onSubmit(tradeInSearchCarVINAction)}
      >
        <Input id="vin" name="vin" type="text" label="VIN" error={getError(errors, 'vin')} />
        <div className={styles.helpText} data-testid="HYGIENE_TRADE_IN_BANNER_TAB_HELP_VIN">
          The VIN can generally be located on the body of the vehicle, under the bonnet, at the bottom of the windscreen
          on the passenger side, along the drivers side door closure area, or on your registration papers.
        </div>
      </TradeInTab>
    </Tabs>
  );
};

interface TradeInTabProps {
  label: ReactNode;
  testId?: string;
  tabHeaderTestId?: string;
  submitTestId: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  children: ReactNode;
}

const TradeInTab: FC<TradeInTabProps> = ({ label, testId, tabHeaderTestId, submitTestId, onSubmit, children }) => (
  <Tab
    className={classNames(styles.content, 'tradeInTabWrapper')}
    label={label}
    testId={testId}
    tabHeaderTestId={tabHeaderTestId}
  >
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.formFields}>{children}</div>
      <Button type="submit" variant="accent" fullWidth data-testid={submitTestId}>
        Value my car
      </Button>
    </form>
    <RecaptchaNotice className={styles.recaptchaNotice} />
  </Tab>
);

const getError = (errors: Record<string, string[] | undefined>, field: string) => errors[field]?.[0];
