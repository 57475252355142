import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { MarkdownLegacy } from 'src/general/components/Markdown/MarkdownLegacy';
import { scaleDownHeadings } from 'src/general/helpers/scaleDownHeadings';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './Disclaimer.module.scss';

// Map all heading elements to be a 'h6' element, to ensure consistency of styling and DOM hierarchy.
const markdownComponentOptions: ComponentProps<typeof MarkdownLegacy>['components'] = scaleDownHeadings(4);

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    content: string;
    textAlign?: 'center' | 'left';
  };
}

export const Disclaimer: FC<Props> = ({ sectionData: { title, content, textAlign }, sectionBackground }) => (
  <section
    className={classNames(
      styles.root,
      { [styles.textAlignCenter]: textAlign === 'center' },
      getSectionClasses(sectionBackground),
    )}
  >
    {title && <h3 className={classNames(styles.header, 'c-fw-bold', 'c-fs-body1')}>{title}</h3>}
    <MarkdownLegacy
      className={classNames(styles.content, 'c-fw-normal', 'c-fs-body2')}
      components={markdownComponentOptions}
    >
      {content}
    </MarkdownLegacy>
  </section>
);
